<template>
  <div v-if="ticket" class="card border border-dark mb-3">
    <div class="card-header py-1">
      <h5 class="card-title text-dark text-center m-0">Ticket Time</h5>
    </div>
    <div class="card-body p-1">
      <div v-if="ticket.completed" class="row">
        <div class="col-4 text-right pr-0">Completed:</div>
        <div class="col-8 px-2">
          {{ completed }}
        </div>
      </div>
      <div v-if="ticket.promised" class="row">
        <div class="col-4 text-right pr-0">Promised:</div>
        <div class="col-8 px-2">
          {{ promised }}
        </div>
      </div>
      <div v-if="ticket.deadline" class="row">
        <div class="col-4 text-right pr-0">Deadline:</div>
        <div class="col-8 px-2">
          {{ deadline }}
        </div>
      </div>
      <div class="row">
        <div class="col-4 text-right pr-0">Delegated:</div>
        <div class="col px-2">
          {{ delegated }}
        </div>
      </div>
      <div class="row">
        <div class="col-4 text-right pr-0">Estimated:</div>
        <div class="col-8 px-2">
          {{ ticket.est_time }}
        </div>
      </div>
      <div class="row">
        <div class="col-4 text-right pr-0">Logged:</div>
        <div class="col-8 px-2">
          {{ timeLogged }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  name: 'TicketTime',
  props: {
    ticket: {
      type: Object,
      default () { return null }
    }
  },
  computed: {
    /**
     * @returns {Number}
     */
    timeLogged () {
      var t = 0
      if (this.ticket && this.ticket.jobs && this.ticket.jobs.length) {
        this.ticket.jobs.forEach((j) => {
          const jt = parseFloat(j.totaltime) || 0
          // totaltime stored to 4 decimal places, remove them for math
          t += Math.round(jt * 10000)
        })
      }
      // round to two decimals, convert back to decimal
      return Math.round(t / 100) / 100
    },
    /**
     * @returns {String}
     */
    completed () {
      return this.dateFormat(this.ticket.completed)
    },
    /**
     * @returns {String}
     */
    promised () {
      return this.dateFormat(this.ticket.promised)
    },
    /**
     * @returns {String}
     */
    delegated () {
      return this.dateFormat(this.ticket.delegated)
    },
    /**
     * @returns {String}
     */
    deadline () {
      return this.dateFormat(this.ticket.deadline)
    }
  },
  methods: {
    dateFormat (input) {
      return moment(input).format('Y-MM-DD hh:mm:ss a')
    }
  }
}
</script>
