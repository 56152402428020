<template>
  <div>
    <div class="row form-group">
      <div class="col-xs-3 col-md-2">
        <label for="jobtype_id">Job Type:</label>
      </div>
      <div class="col">
        <select
          class="form-control"
          name="jobtype_id"
          v-model="job.jobtype_id"
          required
        >
          <option v-for="t in jobtypes" :key="t.id" v-bind:value="t.id">
            {{ t.name }}
          </option>
        </select>
      </div>
    </div>
    <div v-if="job.company_id && job.jobtype_id == 11" class="row form-group">
      <div class="col-xs-3 col-md-2">
        <label for="location">Location</label>
      </div>
      <div class="col">
        <select class="form-control" name="location" v-model="job.location_id" @input="locationSelected">
          <option value=""></option>
          <option
            v-for="l in company.locationlist"
            :key="l.id"
            v-bind:value="l.id"
          >
            {{ l.name + ", " + l.address }}
          </option>
        </select>
      </div>
    </div>
    <div v-if="job.location_id" class="row form-group">
      <div class="col-xs-3 col-md-2">
        <label for="location">Trip Type</label>
      </div>
      <div class="col">
        <b-radio-group v-model="job.triptype_id" @input="tripTypeSelected">
          <b-form-radio :value="0">None/In Route</b-form-radio>
          <b-form-radio :value="1">One Way</b-form-radio>
          <b-form-radio :value="2">Round Trip</b-form-radio>
        </b-radio-group>
      </div>
    </div>
    <div v-if="job.location_id" class="row form-group">
      <div class="col-xs-3 col-md-2">
        <label for="location">Mileage</label>
      </div>
      <div class="col">
        <b-input v-model="job.miles" type="number"></b-input>
      </div>
    </div>
    <div v-if="job.location_id" class="row form-group">
      <div class="col-6 col-xs-3 col-md-2">
        <label for="location">Company Vehicle</label>
      </div>
      <div class="col">
        <b-checkbox v-model="job.companyvehicle" value="yes" unchecked-value="no"></b-checkbox>
      </div>
    </div>
    <div v-if="job.location_id" class="row form-group">
      <div class="col-xs-3 col-md-2">
        <label for="location">Travel Time <small>(Hours)</small></label>
      </div>
      <div class="col">
        <b-input v-model="job.traveltime" type="number"></b-input>
      </div>
    </div>
  </div>
</template>

<script>
import MapService from '@/services/MapService'

export default {
  props: {
    job: {
      type: Object
    },
    company: {
      type: Object
    },
    jobtypes: {
      type: Array
    }
  },
  methods: {
    setMileage: function (locationId, triptype) {
      if (!locationId || locationId < 1 || this.company.locationlist.length === 0) {
        return
      }

      if (triptype === 0) {
        this.job.miles = 0
        this.job.traveltime = 0
        return
      }

      var address = ''
      this.company.locationlist.forEach(e => {
        if (+e.id === +locationId) {
          address = e.address
        }
      })

      MapService.getTrip(address).then(
        (response) => {
          var duration = (response.duration / 60) / 60
          if (triptype === 1) {
            this.job.miles = (response.miles).toFixed(2)
            this.job.traveltime = (duration).toFixed(2)
          } else if (triptype === 2) {
            this.job.miles = (response.miles * 2).toFixed(2)
            this.job.traveltime = (duration * 2).toFixed(2)
          } else {
            this.job.miles = 0
            this.job.traveltime = 0
          }
        }
      ).finally(() => {
        // need to refresh screen so new number shows correctly
        this.$forceUpdate()
      })
    },
    locationSelected: function (event) {
      this.setMileage(+event.target.value, +this.job.triptype_id)
    },
    tripTypeSelected: function (event) {
      this.setMileage(+this.job.location_id, +event)
    }
  },
  watch: {
    'job.triptype_id': function (n, o) {
      if (n !== o) {
        this.setMileage()
      }
    }
  }
}
</script>
