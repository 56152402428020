<template>
  <div>
    <b-navbar toggleable="lg" type="light" variant="light" fixed="top">
      <b-navbar-brand href="/">
        <img
          :src="'/img/aim_logo.png'"
          class="img-responsive"
          style="max-height: 30px; margin: 0 auto"
        />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/jobs">Jobs</b-nav-item>
          <b-nav-item-dropdown>
            <template slot="button-content">Tickets</template>
            <b-dropdown-item to="/tickets/delegate">Delegate</b-dropdown-item>
            <b-dropdown-item to="/tickets/add">Add</b-dropdown-item>
            <b-dropdown-item to="/tickets/search">Summary</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item to="/contacts">Contacts</b-nav-item>
          <b-nav-item to="/timeoffrequest">Time Off Request</b-nav-item>
          <b-nav-item to="/rocketchat">RC</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto" v-if="username">
          <!-- <b-nav-item-dropdown text="Lang" right>
            <b-dropdown-item href="#">EN</b-dropdown-item>
            <b-dropdown-item href="#">ES</b-dropdown-item>
            <b-dropdown-item href="#">RU</b-dropdown-item>
            <b-dropdown-item href="#">FA</b-dropdown-item>
          </b-nav-item-dropdown>-->
          <button
            type="button"
            class="btn"
            v-on:click="installer()"
            v-show="installBtn"
          >
            Install App
          </button>
          <!-- <button
            type="button"
            class="btn"
            v-on:click="subscribe()"
            v-show="isSubscribed == false && !notificationsBlocked"
          >Enable Notifications</button>
          <button
            type="button"
            class="btn"
            v-on:click="unsubscribe()"
            v-show="isSubscribed == true"
          >Disable Notifications</button>
          <button
            type="button"
            class="btn btn-danger"
            v-on:click="showUnblock()"
            v-show="notificationsBlocked"
          >Notifications Blocked</button>-->
          <b-nav-item-dropdown right v-if="username">
            <!-- Using 'button-content' slot -->
            <template v-slot:button-content>
              <em>{{ username }}</em>
            </template>
            <!-- <b-dropdown-item href="#">Profile</b-dropdown-item> -->
            <b-dropdown-item href="#" v-on:click.prevent="logout()"
              >Sign Out</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-modal id="unblock-notifications-instructions"
      >To Unblock do stuff in the browser.</b-modal
    >
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import { applicationServerPublicKey } from '@/services/ApplicationKeys'

export default {
  data () {
    return {
      isSubscribed: false,
      notificationsBlocked: false,
      username: null,
      installPrompt: null,
      installBtn: false
    }
  },
  methods: {
    logout: function () {
      const self = this
      UserService.logout()
      this.clearUsername()
      this.username = null
      self.$router.push('/login', function () {
        self.$notify({
          group: 'notices',
          title: 'Logout Complete',
          text: 'You are now logged out.',
          type: 'alert-success'
        })
      })
    },
    assignUsername: function () {
      const user = UserService.getUser()
      if (!user) {
        this.username = null
        return
      }
      this.username = user.info.firstname + ' ' + user.info.lastname
      setTimeout(() => {
        this.assignUsername()
      }, 30000)
    },
    clearUsername: function () {
      this.username = null
    },
    showUnblock: function () {
      this.$bvModal.show('unblock-notifications-instructions')
    },
    checkSubscription: function () {
      if ('serviceWorker' in navigator) {
        // alternatively Notification.permission
        navigator.serviceWorker.getRegistration().then(registration => {
          if (registration) {
            registration.pushManager.getSubscription().then(subscription => {
              if (subscription === null) {
                this.isSubscribed = false
                this.checkblocked()
              } else {
                this.isSubscribed = true
              }
            })
          }
        })
      }
    },
    checkblocked: function () {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistration().then(registration => {
          registration.pushManager.permissionState({
            userVisibleOnly: true,
            applicationServerKey: applicationServerPublicKey
          }).then(state => {
            if (state === 'denied') {
              this.notificationsBlocked = true
            }
          })
        })
      }
    },
    subscribe: function () {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistration().then(registration => {
          registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: applicationServerPublicKey
          }).then(async subscription => {
            const header = await UserService.authHeader()
            // post to server
            fetch(UserService.baseUrl() + 'api/v1/user/subscribe', {
              method: 'post',
              body: JSON.stringify(subscription),
              headers: header
            })
            this.isSubscribed = true
          })
        })
      }
    },
    unsubscribe: function () {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistration().then(registration => {
          registration.pushManager.getSubscription().then(subscription => {
            subscription.unsubscribe().then(async old => {
              const header = await UserService.authHeader()
              fetch(UserService.baseUrl() + 'api/v1/user/unsubscribe', {
                method: 'post',
                body: JSON.stringify(subscription),
                headers: header
              })
              this.isSubscribed = false
            })
          })
        })
      }
    },
    installer: function () {
      this.installBtn = false
      if (!this.installPrompt) {
        return
      }
      this.installPrompt.prompt()
      this.installPrompt.userChoice.then(result => {
        if (result.outcome === 'accepted') {
          console.log('User accepted!')
        } else {
          console.log('User denied')
        }
        this.installPrompt = null
      })
    },
    showInstallPwaBtn: function () {
      window.addEventListener('beforeinstallprompt', e => {
        e.preventDefault()
        this.installPrompt = e
        this.installBtn = true
      })
    }
  },
  mounted: function () {
    this.checkSubscription()
    this.assignUsername()
  }
}
</script>

<style scoped>
.navbar {
  padding: 0 1rem;
}
.dropdown-menu {
  font-size: inherit;
  border: 0;
}
</style>
