<template>
  <div class="fadeInDown">
    <router-view />
  </div>
</template>

<script>
import UserService from '@/services/UserService'

export default {
  name: 'Jobs',
  data: function () {
    return {
      user: null
    }
  },
  methods: {
  },
  created: function () {
    // reset any loading stuff in case we were redirected from another module and auth expired
    this.$updateloading(-999)
    this.user = UserService.getUser()
  }
}
</script>
