<template>
  <div v-if="ticket" class="card border border-dark mb-3">
    <div class="card-header py-1">
      <h5 class="card-title text-dark text-center m-0">Requester Info</h5>
    </div>
    <div class="card-body p-1">
      <div class="row row-cols-2">
        <div class="col-4 text-right pr-0">Company:</div>
        <div class="col-8 px-2">
          <span v-if="ticket.company">
            {{ ticket.company.name }} (#{{ ticket.company.id }})
          </span>
        </div>
        <div class="col-4 text-right pr-0">Contact:</div>
        <div class="col-8 px-2">
          <span v-if="ticket.contact">
            {{ ticket.contact.firstname + " " + ticket.contact.lastname }}
          </span>
          <span v-else>
            {{ ticket.requester.name }}
          </span>
        </div>
        <div class="col-4 text-right pr-0">Email:</div>
        <div class="col-8 px-2">
          <span v-if="ticket.contact">
            <a :href="'mailto:' + ticket.contact.email">
              {{ ticket.contact.email }}
            </a>
          </span>
          <span v-else>
            <a :href="'mailto:' + ticket.requester.email">
              {{ ticket.requester.email }}
            </a>
          </span>
        </div>
        <div class="col-4 text-right pr-0">Phone:</div>
        <div class="col-8 px-2">
          <span v-if="ticket.contact">
            <a :href="'tel:' + ticket.contact.phone">
              {{ ticket.contact.phone }}
            </a>
          </span>
          <span v-else>
            {{ ticket.requesterphone }}
          </span>
        </div>
        <div class="col-4 text-right pr-0">Submitted:</div>
        <div class="col-8 px-2">
          {{ submittedDate }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'RequesterInfo',
  props: {
    ticket: {
      type: Object,
      default () { return null }
    }
  },
  computed: {
    /**
     * @returns {String}
     */
    submittedDate () {
      return moment(this.ticket.submitted).format('Y-MM-DD HH:mm:ss a')
    }
  }
}
</script>
