<style scoped></style>
<template>
  <div class="wrapper fadeInDown">
    <div id="formContent">
      <!-- Tabs Titles -->
      <div class="container">
        <!-- Icon -->
        <div class="fadeIn first img-wrap">
          <img src="/img/aim_logo.png" id="icon" alt="AIM IT Icon" />
        </div>

        <!-- Login Form -->
        <form class="form" @submit.prevent="login()">
          <label class="fadeIn second">Username</label>
          <input
            type="text"
            id="login"
            class="fadeIn second form-control"
            name="login"
            placeholder="username"
            v-model="username"
            required
          />
          <label class="fadeIn third">Password</label>
          <input
            type="password"
            id="password"
            class="fadeIn third form-control"
            name="login"
            placeholder="password"
            v-model="password"
            required
          />
          <br />

          <button
            style="margin:10px 0;min-width:150px;"
            type="submit"
            class="fadeIn fourth btn btn-primary"
          >
            Login
            <span class="oi oi-account-login"></span>
          </button>
        </form>
      </div>
      <br />
      <!-- Remind Passowrd -->
      <div id="formFooter">
        <a class="underlineHover" @click="installer()" :style="{'display' : installBtn}">Install PWA</a>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import UserService from '@/services/UserService'

export default {
  name: 'LoginForm',
  data: function () {
    return {
      username: '',
      password: '',
      installBtn: 'none',
      installPrompt: null
    }
  },
  watch: {
    username: function (n, o) { }
  },
  computed: {},
  methods: {
    installer: function () {
      this.installBtn = 'none'
      if (!this.installPrompt) {
        return
      }
      this.installPrompt.prompt()
      this.installPrompt.userChoice.then((result) => {
        if (result.outcome === 'accepted') {
          console.log('User accepted!')
        } else {
          console.log('User denied')
        }
        this.installPrompt = null
      })
    },
    showInstallPwaBtn: function () {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault()
        this.installPrompt = e
        this.installBtn = 'block'
      })
    },
    login: async function () {
      const self = this
      // show loading while checking master
      this.$updateloading(1)
      UserService.login(this.username, this.password).then(
        function (user) {
          self.$updateloading(-1)
          self.$router.go('/jobs')
        },
        function (failed) {
          self.$updateloading(-1)
          // https://github.com/euvl/vue-notification
          self.$notify({
            group: 'errors',
            title: 'Login Failed',
            text: failed,
            type: 'warn'
          })
        }
      )
    },
    update: _.debounce(function (e) {
      this.input = e.target.value
    }, 300)
  },
  created: function () {
    // reset any loading stuff in case we were
    // redirected from another module and auth expired
    this.$updateloading(-999)
    // prepopulate company id if exists from previous login
    if (UserService.loggedIn()) {
      this.$router.push({ path: '/jobs' })
    }
    this.showInstallPwaBtn()
  }
}
</script>
