const MapService = {
  getTrip (goTo) {
    const directionService = new window.google.maps.DirectionsService()
    const origin = '4720 E Cotton Gin Loop, Phoenix, AZ 85040'

    const request = {
      origin: origin,
      destination: goTo,
      travelMode: window.google.maps.DirectionsTravelMode.DRIVING,
      unitSystem: window.google.maps.UnitSystem.IMPERIAL,
      provideRouteAlternatives: true
    }

    const trip = {
      miles: 0,
      duration: 0
    }

    return new Promise((resolve, reject) => {
      directionService.route(request, function (response, status) {
        if (status === window.google.maps.DirectionsStatus.OK) {
          for (let i = 0; i < response.routes.length; i++) {
            let tempDistanceMeters = 0
            let tempTime = 0
            for (let l = 0; l < response.routes[i].legs.length; l++) {
              tempDistanceMeters += response.routes[i].legs[l].distance.value
              tempTime += response.routes[i].legs[l].duration.value
            }

            const tempMiles = tempDistanceMeters * 0.000621

            if (tempMiles > trip.miles) {
              trip.miles = tempMiles
              trip.duration = tempTime
            }
          }
          resolve(trip)
        }
      })
    })
  }
}

export default MapService
