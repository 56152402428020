<template>
  <div v-if="info" class="card">
    <div class="card-header py-2">
      <h1 class="card-title m-0 text-dark d-inline">Ticket #{{ info.id }}</h1>
    </div>
    <div class="card-body p-2">
      <div class="row">
        <div class="col-md-6 col-sm-12 d-flex">
          <TicketInfo :ticket="info" class="flex-fill"></TicketInfo>
        </div>
        <div class="col-md-6 col-sm-12 d-flex">
          <RequesterInfo :ticket="info" class="flex-fill"></RequesterInfo>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <Description :ticket="info" @refresh="init()"></Description>
        </div>
      </div>
      <div class="row">
        <div
          v-if="info.jobs && info.jobs.length"
          class="col-sm-12 col-md-7 col-lg-8 order-xs-last"
        >
          <JobUpdates :ticket="info"></JobUpdates>
        </div>
        <div class="col order-xs-first">
          <TicketTime :ticket="info"></TicketTime>
        </div>
      </div>
      <div class="card border border-dark mb-3" v-if="info.files">
        <div class="card-header">
          <h5 class="card-title text-dark m-0">Files</h5>
        </div>
        <div class="card-body">
          <div class="row mb-2" v-for="file in info.files" :key="file.id">
            <div class="col-12">
              <a :href="file.link" class="mr-4">
                {{ file.name }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
</style>
<script>
import TicketService from '@/services/TicketService'
import TicketInfo from '@/components/tickets/templates/TicketInfo'
import RequesterInfo from '@/components/tickets/templates/RequesterInfo'
import TicketTime from '@/components/tickets/templates/TicketTime'
import JobUpdates from '@/components/tickets/templates/JobUpdates'
import Description from '@/components/tickets/templates/Description'

export default {
  name: 'TicketDetail',
  components: {
    TicketInfo,
    RequesterInfo,
    TicketTime,
    JobUpdates,
    Description
  },
  props: {
    id: {
      type: [Number, String],
      default () { return 0 }
    },
    ticket: {
      type: Object,
      default () { return null }
    }
  },

  data () {
    return {
      loading: false,
      info: null
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    init () {
      if (this.ticket !== null) {
        this.info = Object.assign({}, this.ticket)
      } else if (this.id > 0) {
        this.load(this.id)
      }
    },

    load (id) {
      this.$updateloading(1)
      TicketService.get(id).then(
        response => {
          this.info = response.data
        }, error => {
          if (error.response.status === 403) {
            this.$notify({
              group: 'errors',
              type: 'danger',
              title: 'Permission Denied',
              text: 'You do not have permissions to view tickets.'
            })
            this.$router.go(-1)
          }
        }
      )
        .finally(() => {
          this.$updateloading(-1)
        })
    }
  }
}
</script>
