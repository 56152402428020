<template>
  <div
    v-if="ticket && ticket.jobs && ticket.jobs.length > 0"
    class="card border border-dark mb-3"
  >
    <div class="card-header py-1">
      <h5 class="card-title text-dark text-center m-0">Updates</h5>
    </div>
    <div class="card-body py-1 px-0">
      <ul class="list-group list-group-flush mb-0">
        <li
          v-for="j in ticket.jobs"
          :key="j.id"
          class="list-group-item border-light border-bottom m-0"
        >
          <div class="row no-gutters">
            <div class="col-xs col-xs-auto text-center pr-2">
              <strong :title="'Job ID #' + j.id">
                {{ getJobDate(j.jdate) }}
                <br />
                {{ getJobUser(j) }}
              </strong>
            </div>
            <div class="col px-1"><span v-html="j.descriptionhtml"></span></div>
            <div class="col-xs col-xs-auto text-center pl-2">
              <strong>Time:<br />{{ j.totaltime }}</strong>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'JobUpdates',
  props: {
    ticket: {
      type: Object,
      default () { return null }
    }
  },
  methods: {
    getJobDate (jobdate) {
      const d = new Date(jobdate)
      return (d.getMonth() + 1).toString().padStart(2, '0') + '-' + d.getDate().toString().padStart(2, '0')
    },
    getJobUser (job) {
      return (job && job.user) ? job.user.lastname + job.user.firstname[0] : 'Unknown User'
    }
  }
}
</script>
