import UserService from '@/services/UserService'
import axios from 'axios'

const ContactService = {

  async get (id) {
    return axios({
      url: UserService.baseUrl() + 'api/contacts/' + id,
      method: 'GET'
    })
  },

  async getTechs () {
    return axios({
      url: UserService.baseUrl() + 'api/contacts/getTechs',
      method: 'GET'
    })
  },

  async search (filters) {
    return axios({
      url: UserService.baseUrl() + 'api/contacts/search',
      method: 'POST',
      data: JSON.stringify({ filters: filters })
    })
  },

  async mailmerge (id) {
    return axios({
      url: UserService.baseUrl() + 'api/contacts/mailmerge/' + id,
      method: 'GET'
    })
  },

  async update (id, data) {
    return axios({
      url: UserService.baseUrl() + 'api/contacts/' + id,
      headers: { 'content-type': 'application/json' },
      method: 'PATCH',
      data: JSON.stringify(data)
    })
  },

  async retire (id) {
    return axios({
      url: UserService.baseUrl() + 'api/contacts/retire/' + id,
      method: 'PATCH'
    })
  },

  async delete (id) {
    return axios({
      url: UserService.baseUrl() + 'api/contacts/' + id,
      method: 'DELETE'
    })
  },

  async sendContact (contactid, data) {
    return axios({
      url: UserService.baseUrl() + 'api/contacts/sendcontact/' + contactid,
      method: 'POST',
      data: JSON.stringify(data)
    })
  }

}

export default ContactService
