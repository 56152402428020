<template>
  <div class="wrapper fadeInDown">
    <div class="card">
      <div class="card-header w-100 p-1 py-2">
        <div class="d-flex h-100 p-0">
          <div class="row align-self-center w-100 p-0 m-0">
            <div class="col">
              <div v-if="this.job.id > 0">
                <h1 class="p-0 m-0">Edit Job</h1>
              </div>
              <div v-else>
                <h1 class="p-0 m-0">Add Job</h1>
              </div>
            </div>
            <div class="col text-right">
              <router-link
                :to="{ path: '/jobs' }"
                tag="span"
                class="btn btn-info btn-sm"
                >&times;</router-link
              >
            </div>
          </div>
        </div>
      </div>

      <div class="card-body p-0 px-2 mt-3">
        <div>
          <!-- company display and selection -->
          <div class="row form-group">
            <div class="col-xs-3 col-md-2">
              <label for="company_id">Client/Customer:</label>
            </div>
            <div class="col">
              <company-type-ahead-selector
                :selectedCompanyId="job.company_id"
                :hideLabel="true"
                @select-company="selectCompany"
                :required="true"
              ></company-type-ahead-selector>
            </div>
            <!-- <div class="col-12" v-show="responseErrors['company_id']">
              {{ responseErrors["company_id"] }}
            </div> -->
          </div>
          <!-- requester select box -->
          <JobRequesterSelect
            :job="job"
            :company="company"
          ></JobRequesterSelect>

          <JobDeviceSelect :job="job" :company="company"></JobDeviceSelect>

          <JobTypeLocationSelect
            :job="job"
            :company="company"
            :jobtypes="jobtypes"
          ></JobTypeLocationSelect>
          <JobRedmineSelect
            :job="job"
            :company="company"
            :redmineactivities="redmineActivities"
            v-on:update-from-redmine="updateFromRedmine"
          ></JobRedmineSelect>
          <div class="row mb-3">
            <div class="col-xs-3 col-md-2">
              <label for="time">Date:</label>
            </div>
            <div class="col">
              <b-form-datepicker
                v-model="job.jdate"
                size="sm"
              ></b-form-datepicker>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-xs-3 col-md-2">
              <label for="time">Total Time:</label>
            </div>
            <div class="col">
              <div class="row">
                <div class="col form-inline">
                  <div class="form-group">
                    <input
                      class="form-control"
                      name="time"
                      v-model="job.totaltime"
                    />

                    <span
                      v-show="job.starttime !== null"
                      class="text-danger lg"
                    >
                      &nbsp; + {{ jobRunningTime() }} current running time
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-xs-3 col-md-2">
              <label for="time">Ignorance/NC Portion:</label>
            </div>
            <div class="col form-inline">
              <div class="form-group">
                <input
                  class="form-control"
                  name="ignoranceAdjustment"
                  v-model="job.ignoranceadjustment"
                />
              </div>
            </div>
          </div>

          <div class="row form-group">
            <div class="col-xs-3 col-md-2">
              <label for="subject">Documentation:</label>
            </div>
            <div class="col">
              <textarea
                class="form-control"
                name="subject"
                v-model="job.description"
              ></textarea>
            </div>
          </div>

          <div class="row form-group">
            <div class="col-xs-3 col-md-2"></div>
            <div class="col form-inline">
              <div class="form-group">
                <input
                  type="checkbox"
                  name="start"
                  v-model="job.startnow"
                  :value="true"
                />
                <label for="start">Start</label>
              </div>

              <div class="form-group mx-auto">
                <input type="checkbox" name="is911" v-model="job.is911" />
                <label for="is911" class="text-danger">Outside H/S/A</label>
              </div>

              <div class="form-group">
                <input type="checkbox" name="private" v-model="job.private" />
                <label for="private">Private</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer w-100 p-1 py-2">
        <div class="d-flex h-100 p-0">
          <div v-if="this.job.id > 0" class="float-left">
            <button class="btn btn-danger" @click="spawnJobDelete()">
              Delete
            </button>
          </div>
          <router-link
            :to="{ path: '/jobs' }"
            tag="span"
            class="btn btn-secondary ml-auto mr-1"
            >Cancel</router-link
          >
          <div v-if="this.job.id > 0">
            <button class="btn btn-primary" @click="updateJob()">Update</button>
          </div>
          <div v-else>
            <button class="btn btn-primary" @click="saveJob()">Save</button>
          </div>
        </div>
      </div>

      <div class="card" v-if="hardwarecategories.length > 0">
        <div class="card-header w-100">
          <div class="">
            <div class="row align-self-center w-100">
              <div class="col">
                <h5 @click="expandedHardware = !expandedHardware">Hardware</h5>
              </div>
              <div class="col text-right">
                &nbsp;
                <a
                  href="#"
                  v-on:click.prevent="expandedHardware = !expandedHardware"
                >
                  <span
                    class="fas fa-angle-double-down"
                    v-show="!expandedHardware"
                  ></span>
                  <span
                    class="fas fa-angle-double-up"
                    v-show="expandedHardware"
                  ></span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body p-0 px-2 mt-3" v-show="expandedHardware">
          <b-row class="d-none d-md-flex">
            <b-col cols="2"><label>Category</label></b-col>
            <b-col cols="6"><label>Description</label></b-col>
            <b-col cols="1"><label>Qty</label></b-col>
            <b-col cols="1"><label>Price</label></b-col>
            <b-col cols="1"><label>Non Taxable</label></b-col>
            <b-col cols="1">
              <b-button @click="addHardware()">
                <i class="fa fa-plus"></i>
              </b-button>
            </b-col>
          </b-row>
          <JobHardwareSelector
            v-for="(h, k) in job.hardware"
            :key="h.id"
            :hardware="h"
            :categories="hardwarecategories"
            @removeHardware="removeHardware(k)"
          ></JobHardwareSelector>
          <b-button class="d-inline-block d-md-none" @click="addHardware()">
            <i class="fa fa-plus"></i>
            Add Hardware
          </b-button>
        </div>
      </div>

      <JobTicketSelect
        :job="job"
        :company="company"
        @select-ticket="job.ticket_id = $event"
        @ticket-page="getTickets($event)"
      ></JobTicketSelect>
      <job-delete
        :job="job"
        :display="deleteJob"
        @cancel="deleteJob = null"
        @job-deleted="leavePage"
      ></job-delete>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import router from '@/router'
import UserService from '@/services/UserService'
import moment from 'moment'
import JobRequesterSelect from './JobRequesterSelect.vue'
import JobDeviceSelect from './JobDeviceSelect.vue'
import JobTypeLocationSelect from './JobTypeLocationSelect.vue'
import JobRedmineSelect from './JobRedmineSelect.vue'
import JobTicketSelect from './JobTicketSelect.vue'
import JobDelete from './JobDelete.vue'
import JobHardwareSelector from './JobHarwareSelector'
import CompanyTypeAheadSelector from '../form/element/CompanyTypeAheadSelector.vue'
import TicketService from '@/services/TicketService'
// import _ from 'lodash'

export default {
  name: 'JobAddEditForm',
  components: {
    JobRequesterSelect,
    JobDeviceSelect,
    JobTypeLocationSelect,
    JobRedmineSelect,
    JobTicketSelect,
    JobDelete,
    JobHardwareSelector,
    CompanyTypeAheadSelector
  },
  data: function () {
    return {
      // the job record
      job: {
        id: null,
        company_id: null,
        company: { id: null, name: '' },
        contact_id: null,
        jobtype_id: UserService.getUser().info.default_job_type,
        redmine_id: null,
        totaltime: 0.05,
        jdate: new Date(),
        ignoranceadjustment: 0.00,
        description: '???',
        startnow: false,
        starttime: null,
        is911: false,
        private: false,
        ticket_id: null,
        user_id: UserService.getUserId(),
        device_id: null,
        triptype_id: 0,
        companyvehicle: 'no',
        location: {},
        hardware: [],
        removedHardware: []
      },
      // the selected company, holds ticket list, etc.
      company: {
        id: 0,
        /** @type {Array<{ id: Number }>} */
        devices: [],
        /** @type {Array<{ id: Number }>} */
        locationlist: [],
        /** @type {Array<{ id: Number }>} */
        contacts: [],
        /** @type {Array<{ id: Number, subject: string }>} */
        redmineissues: [],
        /** @type {Array<{ id: Number }>} */
        tickets: [],
        ticketLinks: {},
        ticketMeta: {}
      },
      /** @type {Array<{ id: Number }>} */
      redmineActivities: [],
      /** @type {Array<{ id: Number }>} */
      techs: [],
      /** @type {Array<{ id: Number }>} */
      jobtypes: [],
      companySearch: '',
      responseErrors: {},
      deleteJob: 0,
      hardwarecategories: [],
      expandedHardware: false
    }
  },
  methods: {
    /**
    * Init function that retrieves all data points needed for the basics
    * of this form.
    */
    init: async function () {
      // ensure any loader count is 0
      this.$updateloading(-1000)
      const urlParams = new URLSearchParams(window.location.search)
      const ticketid = isNaN(urlParams.get('ticket_id')) ? 0 : +urlParams.get('ticket_id')
      // if editing a job, load the job data after base data has come in
      if (this.$route.params.id > 0) {
        this.job.id = this.$route.params.id
        // load base data
        this.loadData()
          // let main data load then load job
          .then(this.loadJob())
          .catch(err => {
            console.log(err)
          })
      } else if (ticketid > 0) {
        this.job.ticket_id = ticketid
        TicketService.get(this.job.ticket_id).then(
          (response) => {
            if (response && response.data && response.data.company_id) {
              // load base data
              this.loadData(response.data.company_id)
            } else {
              this.job.ticket_id = null
              this.loadData()
            }
          }
        ).catch(() => {
          this.job.ticket_id = null
          this.$notify({
            group: 'errors',
            title: 'Invalid Ticket ID',
            type: 'warn'
          })
          this.loadData()
        })
      } else {
        // otherwise we just need the base data for new jobs
        this.loadData()
      }
    },
    /**
     * Gets a list of jobtypes, etc.
     * All the basics needed to add or edit a job
     * @returns {Promise[]}
     */
    loadData: async function (company = 0) {
      const urlParams = new URLSearchParams(window.location.search)
      const selectCompanyId = urlParams.get('company_id')
      if (selectCompanyId > 0) {
        this.selectCompany(+selectCompanyId)
      } else if (company > 0) {
        this.selectCompany(+company)
      }
      // get company list
      return Promise.all(
        [
          // get job types
          this.getJobTypes(),
          // get redmine activity types
          this.getRedmineTypes(),
          // get harware for the job
          this.getHardware()
        ]
      )
    },
    /** Get job types for drop down selection,
     * and set them to the internal @member {jobtypes} array
     * @returns {Promise}
     */
    getJobTypes: function () {
      this.$updateloading(1)
      return axios.get('api/job_types').then(response => {
        this.jobtypes = response.data
      }).finally(() => {
        this.$updateloading(-1)
      })
    },
    getRedmineTypes: function () {
      this.$updateloading(1)
      return axios.get('api/job_types/redmine').then(response => {
        this.redmineActivities = response.data
      }).finally(() => {
        this.$updateloading(-1)
      })
    },
    getHardware: function () {
      this.$updateloading(1)
      return axios.get('api/hardware/categories').then(response => {
        this.hardwarecategories = response.data
      }).finally(() => {
        this.$updateloading(-1)
      })
    },
    addHardware: function () {
      this.job.hardware.push({
        id: null,
        sku: null,
        category: null,
        description: null,
        quantity: null,
        price: 0,
        nontaxable: 0
      })
    },
    removeHardware: function (index) {
      if (!this.job.removedHardware) {
        Object.assign(this.job, { removedHardware: [] })
      }
      // add this to a removedHardware array if it's an existing record that has already been
      // saved, so we remove it from the DB, if there's no ID, then skip this, and just remove
      // from the internal array so it's not displayed
      var hardwareid = this.job.hardware[index].id
      if (hardwareid && hardwareid > 0 && this.job.removedHardware.indexOf(hardwareid) < 0) {
        this.job.removedHardware.push(hardwareid)
      }
      this.job.hardware.splice(index, 1)
    },
    loadJob: async function () {
      var jobID = this.job.id
      return axios({
        url: UserService.baseUrl() + 'api/jobs/' + jobID,
        method: 'GET'
      }).then(response => {
        this.setJob(response)
      }, error => {
        if (error.response.status === 404) {
          this.$notify({
            group: 'errors',
            title: 'Invalid Job ID',
            type: 'warn'
          })
          this.leavePage()
        }
      })
    },
    setJob: function (response) {
      this.job = response.data
      if (this.job.starttime !== null) {
        this.job.startnow = true
      }
      if (this.job.redmine_id && !this.job.redmineactivityid) {
        this.job.redmineactivityid = 9
        if (this.redmineActivities.length === 0) {
          this.getRedmineTypes()
        }
      }
      this.loadCompany()
    },
    loadCompany: async function () {
      if (!this.job.company_id) {
        return false
      }
      this.$updateloading(1)

      Promise.all([
        this.getTickets(1),
        // get company info (not really needed, is it?)
        axios.get('api/companies/' + this.job.company_id + '?context=jobAddEdit')
          .then(res => {
            // this.jobtypes = res.data
            // set up requesters and pc/server if empty show none for this client
            this.company.contacts = res.data.contacts
            this.company.devices = res.data.devices
            this.company.locationlist = res.data.locations
          })
          .catch(err => {
            console.log(err)
          }),
        // get open redmine issues for company
        axios.get('api/companies/' + this.job.company_id + '/redmineissues')
          .then(
            (response) => {
              // response.data.data = atms tickets
              this.company.redmineissues = response.data
            },
            (error) => {
              console.dir(error)
            }
          )
      ]).finally(() => {
        this.$updateloading(-1)
      })
    },
    // get open tickets for selected company
    getTickets: function (page) {
      page = page || 1
      this.$updateloading(1)
      return axios.get('api/companies/' + this.job.company_id + '/tickets?page=' + page)
        .then(
          (response) => {
            // response.data.data = atms tickets
            this.company.tickets = response.data.data
            this.company.ticketLinks = response.data.links
            this.company.ticketMeta = response.data.meta
          },
          (error) => {
            console.dir(error)
          }
        )
        .finally(() => {
          this.$updateloading(-1)
        })
    },
    saveJob: async function () {
      if (this.job.startnow) {
        this.job.starttime = new Date()
      }
      this.$updateloading(1)
      await axios({
        url: UserService.baseUrl() + 'api/jobs/',
        method: 'POST',
        data: JSON.stringify(this.job)
      }).then(
        (response) => {
          this.leavePage()
        },
        (error) => {
          var errs = []
          for (var i in error.response.data.errors) {
            error.response.data.errors[i].forEach(m => {
              errs.push(m)
            })
          }
          this.$notify({
            group: 'errors',
            title: error.response.data.message,
            text: errs.join('<br>'),
            type: 'warn'
          })
          this.responseErrors = error.response.data.errors
        }
      ).finally(() => {
        this.$updateloading(-1)
      })
    },
    updateJob: async function () {
      var jobdata = this.job
      await axios({
        url: UserService.baseUrl() + 'api/jobs/' + this.job.id,
        method: 'PATCH',
        headers: { 'content-type': 'application/json' },
        data: JSON.stringify(jobdata)
      }).then(
        (response) => {
          router.push('/jobs')
        },
        (error) => {
          var errs = []
          for (var i in error.response.data.errors) {
            error.response.data.errors[i].forEach(m => {
              errs.push(m)
            })
          }
          this.$notify({
            group: 'errors',
            title: error.response.data.message,
            text: errs.join('<br>'),
            type: 'warn'
          })
          this.responseErrors = error.response.data.errors
        }
      )
    },
    updateFromRedmine: function () {
      if (!this.job.redmine_id) {
        return false
      }
      var rm = this.company.redmineissues.find(el => el.id === this.job.redmine_id)
      // if new job always update on change, otherwise confirm ???
      if (this.job.description === '???' || this.job.id === null) {
        this.job.description = rm.subject
        this.job.redmineactivityid = 9
        if (this.redmineActivities.length === 0) {
          this.getRedmineTypes()
        }
      }
      var ticketId = this.getRmTicketId(rm)
      if (ticketId > 0) {
        this.job.ticket_id = ticketId
      }
    },
    getRmTicketId: function (rm) {
      let ticket = { value: null }
      rm.custom_fields.forEach(f => {
        if (f.id === 2) {
          ticket = f
        }
      })
      return ticket.value
    },
    selectCompany: function (comp) {
      if (comp === null) {
        this.clearCompany()
        return
      }
      // when the company-select component returns a selection, it will be an object
      if (typeof comp === 'object') {
        this.job.company_id = comp.id
        this.job.company = comp
      } else {
        // just an int, from the URL probably
        // the company-select component will still end up re-calling this method
        // when it emits the selection
        this.job.company_id = comp
      }
      this.loadCompany()
      this.companySearch = ''
    },
    clearCompany: function () {
      this.job.company_id = null
      this.job.company = {}
      this.company = {
        id: 0,
        /** @type {Array<{ id: Number }>} */
        devices: [],
        /** @type {Array<{ id: Number }>} */
        locationlist: [],
        /** @type {Array<{ id: Number }>} */
        contacts: [],
        /** @type {Array<{ id: Number, subject: string }>} */
        redmineissues: [],
        /** @type {Array<{ id: Number }>} */
        tickets: []
      }
      this.companySearch = ''
    },
    jobRunningTime: function () {
      if (this.job.starttime === null) {
        return 0
      }
      const st = moment(this.job.starttime)
      const nw = moment()
      return ((nw.unix() - st.unix()) / (60 * 60)).toFixed(2)
    },
    leavePage: function () {
      router.push('/jobs')
    },
    spawnJobDelete: function () {
      this.deleteJob = this.job.id
    }
  },
  updated: function () {
  },
  created: function () {
    // reset any loading stuff in case we were redirected from another module and auth expired
    this.init()
  },
  beforeDestroy: function () {
    // making sure other pages don't get locked into loading
  }
}
</script>

<style scoped>
</style>
