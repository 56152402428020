import Vue from 'vue'
import VueRouter from 'vue-router'
import UserService from '@/services/UserService'
import PageNotFound from '@/views/PageNotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/jobs'
  },
  {
    path: '/login',
    name: 'login',
    meta: { layout: 'default', title: 'Login' },
    component: require('@/views/Login.vue').default
  },
  {
    path: '/login/hash',
    name: 'loginhash',
    meta: { layout: 'default', title: 'Login' },
    props: true,
    component: require('@/views/LoginHash.vue').default
  },
  {
    path: '/jobs',
    name: 'jobs',
    meta: { layout: 'default', title: 'Jobs' },
    component: require('@/views/Jobs.vue').default,
    redirect: '/jobs',
    children: [
      {
        path: '',
        meta: { layout: 'default', title: 'Jobs' },
        component: require('@/components/jobs/JobList.vue').default
      },
      {
        path: 'add/:ticketid?',
        meta: { layout: 'default', title: 'Add Job' },
        component: require('@/components/jobs/JobAddEdit.vue').default
      },
      {
        path: 'edit/:id',
        meta: { layout: 'default', title: 'Edit Job' },
        component: require('@/components/jobs/JobAddEdit.vue').default
      }
    ]
  },
  {
    path: '/tickets',
    name: 'tickets',
    meta: { layout: 'default', title: 'Tickets' },
    component: require('@/views/Tickets.vue').default,
    redirect: '/tickets/search',
    children: [
      {
        path: 'view',
        redirect: '/tickets/search'
      },
      {
        path: 'view/:id',
        meta: { layout: 'default', title: 'Ticket' },
        component: require('@/components/tickets/Detail.vue').default,
        props: true
      },
      {
        path: 'add',
        meta: { layout: 'default', title: 'Ticket' },
        component: require('@/components/tickets/TicketAddEdit').default
      },
      {
        path: 'edit/:id',
        props: true,
        meta: { layout: 'default', title: 'Ticket' },
        component: require('@/components/tickets/TicketAddEdit').default
      },
      {
        path: 'search',
        props: true,
        meta: { layout: 'default', title: 'Ticket' },
        component: require('@/components/tickets/TicketSearch').default
      },
      {
        path: 'delegate',
        meta: { layout: 'default', title: 'Ticket' },
        component: require('@/components/tickets/TicketDelegate').default
      }
    ]
  },
  {
    path: '/contacts',
    name: 'contacts',
    meta: { layout: 'default', title: 'Contacts' },
    component: require('@/views/Contacts.vue').default
  },
  {
    path: '/timeoffrequest',
    name: 'timeoff',
    meta: { layout: 'default', title: 'Time Off Request' },
    component: require('@/views/TimeOffRequest.vue').default
  },
  {
    path: '/rocketchat',
    name: 'rocketchat',
    meta: { layout: 'default', title: 'Rocket Chat' },
    component: require('@/views/Rocketchat.vue').default
  },
  {
    path: '/envelope/:id',
    name: 'envelope',
    meta: { layout: 'default', title: 'Envelope', printout: true },
    props: true,
    component: require('@/components/Envelope.vue').default
  },
  { path: '*', component: PageNotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login']
  const authRequired = (!publicPages.includes(to.path) && !to.path.startsWith('/login'))
  const loggedIn = UserService.loggedIn()

  if (authRequired && !loggedIn) {
    return next('/login')
  }

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = 'ATMS - ' + nearestWithTitle.meta.title

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag))

  next()
})

export default router
