<template>
  <b-container fluid>
    <div class="wrapper fadeInDown">
      <b-card>
        <div v-if="showForm">
          <form @submit.prevent="submitToken()" ref="tokenForm">
            <b-form-group label="Rocket Chat ID">
              <b-form-input v-model="form.id" :state="valid.id" @blur="checkValidity('id')" @input="checkValidity('id')"></b-form-input>
              <b-form-invalid-feedback>Rocket Chat ID is required</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Rocket Chat Token">
              <b-form-input v-model="form.token" :state="valid.token" @blur="checkValidity('token')" @input="checkValidity('token')"></b-form-input>
              <b-form-invalid-feedback>Rocket Chat token is required</b-form-invalid-feedback>
            </b-form-group>
            <b-button type="submit" :disabled="!valid.token || !valid.id">Submit Token</b-button>
          </form>
          <br>
          To generate a token:
          <ul>
            <li>Click Your Avatar in Rocket.Chat</li>
            <li>Click on My Account</li>
            <li>Click on Personal Access Tokens</li>
            <li>Enter a Name (e.g. "ATMS"), and click Add</li>
            <li>You will receive a Pop-Up with your Token and Rocket.Chat User ID. Enter those fields in the form above.</li>
          </ul>
        </div>
        <div v-else>
          <b-row>
            <b-col cols="12" md="3">
              <b-button @click="showTokenForm()">Edit Token</b-button>
            </b-col>
            <b-col cols="12" md="9" class="mt-3 mt-md-0">
              <b-input-group>
                <b-form-input debounce="500" placeholder="Search" v-model="text" v-on:keyup.enter="getMessages()"></b-form-input>
                <b-input-group-append>
                  <b-button variant="primary" @click="getMessages()"><b-icon icon="search"></b-icon></b-button>
                  <b-button @click="text = ''"><b-icon icon="x"></b-icon></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
    <div class="wrapper fadeInDown">
      <b-card style="overflow: auto;">
        <b-table
          :items="messages"
          :fields="fields"
          sort-by="_updatedAt"
          :sort-desc="true"
          :show-empty="searched"
          :filter="text"
          :busy.sync="loading"
        >
          <template #cell(_updatedAt)="data">
            {{ data.item._updatedAt | date }}
          </template>
          <template #cell(u)="data">
            {{ data.item.u.username }}
          </template>
          <template #cell(msg)="data">
            {{ data.item.msg }}
            <div v-if="data.item.attachments && data.item.attachments.length > 0">
              <div v-for="img in data.item.attachments" :key="img._id">
                <a href="javascript:void(0)" @click="downloadImg(img)">
                  <img v-if="img.image_type" :src="'https://rc.aimitservices.com' + img.image_url" :alt="img.title" style="max-width: 20vw; max-height: 40vh;">
                  <span v-else>{{ img.title }}</span>
                </a>
              </div>
            </div>
          </template>
          <template #cell(action)="data">
            <b-button @click="unstar(data.item._id)" variant="success" class="p-2"><b-icon icon="check"></b-icon></b-button>
          </template>
        </b-table>
      </b-card>
    </div>
  </b-container>
</template>
<script>
import RocketChatService from '@/services/RocketChatService'
import moment from 'moment'

export default {
  data () {
    return {
      form: {
        id: null,
        token: null
      },
      valid: {
        id: null,
        token: null
      },
      showForm: false,
      messages: [],
      fields: [
        { key: '_updatedAt', label: 'Date', sortable: true, sortDirection: 'desc' },
        { key: 'channels', label: 'Channel' },
        { key: 'u', label: 'Sender' },
        { key: 'msg', label: 'Message' },
        'action'
      ],
      searched: false,
      text: '',
      loading: false
    }
  },

  created () {
    this.init()
  },

  methods: {
    init () {
      this.$updateloading(1)
      RocketChatService.getCredentials().then(
        (response) => {
          if (response.data.id) {
            this.form.id = response.data.id
          }
          if (response.data.token) {
            this.form.token = response.data.token
          }
          if (!this.form.id || !this.form.token) {
            this.showForm = true
          }
        }
      ).finally(() => {
        this.$updateloading(-1)
      })

      if (this.showForm) {
        return
      }

      this.getMessages()
    },

    getMessages () {
      this.$updateloading(1)
      this.loading = true
      this.messages = []
      RocketChatService.getMessages().then(
        (response) => {
          this.messages = response.data.messages
        }
      ).catch((err) => {
        this.$notify({
          group: 'errors',
          title: err.response.data.message,
          type: 'warn'
        })
      }).finally(() => { this.searched = true; this.loading = false; this.$updateloading(-1) })
    },

    unstar (id) {
      this.$updateloading(1)
      this.loading = true
      RocketChatService.unstar(id).then(
        (response) => {
          this.messages = []
          this.messages = response.data.messages
        }
      ).catch((err) => {
        this.$notify({
          group: 'errors',
          title: err.response.data.message,
          type: 'warn'
        })
      }).finally(() => { this.searched = true; this.loading = false; this.$updateloading(-1) })
    },

    showTokenForm () {
      this.checkValidity('token')
      this.checkValidity('id')

      this.showForm = true
    },

    submitToken () {
      this.checkValidity('token')
      this.checkValidity('id')

      if (!this.valid.token || !this.valid.id) {
        return false
      }

      RocketChatService.setCredentials(this.form.id, this.form.token).then(
        (response) => {
          this.$notify({
            group: 'notices',
            title: 'RC Credentials Set',
            type: 'info'
          })

          this.showForm = false
        }
      ).catch((err) => {
        this.$notify({
          group: 'errors',
          title: err.response.data.message,
          type: 'warn'
        })
      })

      this.getMessages()
    },

    checkValidity (item) {
      if (!this.form[item] || this.form[item] === '') {
        this.valid[item] = false
      } else {
        this.valid[item] = true
      }
    },

    downloadImg (img) {
      this.$updateloading(1)
      RocketChatService.downloadFile(img).then(
        (response) => {
          const type = img.image_type ? img.image_type : 'application/octet-stream'
          const a = document.createElement('a')
          a.href = 'data:' + type + ';base64,' + response.data
          a.download = img.title
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      ).finally(() => { this.$updateloading(-1) })
    }
  },

  filters: {
    date (val) {
      return moment(val).format('YYYY-MM-DD hh:mm A')
    }
  }
}
</script>
