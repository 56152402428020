import router from '@/router'
import Vue from 'vue'
import Notifications from 'vue-notification'

Vue.use(Notifications)

const UserService = {

  baseUrl () {
    // return base URL for API calls
    if (typeof process !== 'undefined' && process.env.NODE_ENV === 'development') {
      return 'https://atmsapi.dev.aimitservices.com/'
    } else if (typeof process !== 'undefined' && process.env.NODE_ENV === 'staging') {
      return process.env.VUE_APP_STAGING_API_URL
    } else {
      return 'https://atmsapi.aimitservices.com/'
    }
  },

  setLocalStorages (user, date = new Date()) {
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('lastEntry', JSON.stringify(date))
  },

  async authHeader () {
    const token = this.getToken()
    // if no user storage, return null
    if (!token) {
      this.redirectToLogin('Your session has expired. Please login again.')
      return
    }
    // return authorization header with jwt token
    return token
  },

  getToken () {
    const user = this.getUserObject()
    if (user === null) {
      this.logout()
      return null
    } else {
      return user.token
    }
  },

  // because can not put http:// into url in get function, so remove it, then put it back in the future
  // removeUrlPrefix (url) {
  //  return url.replace(/^http(s?):\/\//i, '').replace(/\/$/, '')
  // },

  async login (username, password) {
    return fetch(this.baseUrl() + 'api/login', {
      method: 'POST',
      body: JSON.stringify({
        username: username,
        password: password
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      return this.handleResponse(response)
    })
      .then(user => {
        // login successful if there's a jwt token in the response
        if (user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.setLocalStorages(user)
        }
        return user
      })
  },

  loginHash (userId, hash) {
    return fetch(this.baseUrl() + 'api/loginhash', {
      method: 'POST',
      body: JSON.stringify({
        hash: hash,
        userId: userId
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      return this.handleResponse(response)
    }).then(user => {
      // login successful if there's a jwt token in the response
      if (user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        this.setLocalStorages(user)
      }
      return user
    })
  },

  logout () {
    // remove user from local storage to log user out
    localStorage.clear()
  },

  loggedIn () {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user === null) {
      return false
    } else if (typeof user.token === 'undefined') {
      this.logout()
    } else {
      return true // todo verify token and expiration stuff
    }
  },

  getUserObject () {
    const temp = localStorage.getItem('user')
    if (temp !== null) {
      return JSON.parse(temp)
    } else {
      return null
    }
  },

  getUser () {
    return this.getUserObject()
  },

  getUserId () {
    const u = this.getUser()
    return u.info.id
  },

  handleResponse (response) {
    return response.text().then(text => {
      const data = text && JSON.parse(text)
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          this.logout()
          // location.reload(true);
        }

        const error = (data && data.error) || response.statusText
        return Promise.reject(error)
      }

      return Promise.resolve(data)
    })
  },

  redirectToLogin (message) {
    this.logout()
    router.push('/login', () => {
      Vue.notify({
        group: 'notices',
        title: 'Logout Complete',
        text: message,
        type: 'danger'
      })
    })
  }
}

export default UserService
