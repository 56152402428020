<template>
  <div class="wrapper fadeInDown">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'Tickets'
}
</script>
