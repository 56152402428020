<template>
  <div>
    <div class="wrapper fadeInDown">
      <form>
        <b-card no-body>
          <b-card-header>
            <h1 class="p-0 m-0">Time Off Request</h1>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col cols="12" md="6" v-if="isManager">
                <b-form-group label="Employee">
                  <b-form-select
                    :options="employees"
                    value-field="id"
                    text-field="name"
                    v-model="form.user_id"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Time Off Type">
                  <b-form-select v-model="form.type">
                    <b-form-select-option :value="0"
                      >Personal</b-form-select-option
                    >
                    <b-form-select-option :value="2"
                      >Vacation</b-form-select-option
                    >
                    <b-form-select-option :value="1"
                      >Sick Leave EPST</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="Start Date">
                  <b-form-datepicker
                    v-model="form.startdate"
                    :min="minStart"
                    locale="en"
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group
                  label="End Date"
                  description="* last date, not date you come back to work"
                >
                  <b-form-datepicker
                    v-model="form.enddate"
                    :date-disabled-fn="restrictEndDate"
                    locale="en"
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6" v-if="showDay">
                <b-form-group label="Day Section">
                  <b-form-select v-model="form.daysection">
                    <b-form-select-option :value="0"
                      >Full Day</b-form-select-option
                    >
                    <b-form-select-option :value="1"
                      >Morning</b-form-select-option
                    >
                    <b-form-select-option :value="2"
                      >Afternoon</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group
                  label="Request As"
                  description="* paid time off is subject to accured amount at the time of the leave"
                >
                  <b-form-select v-model="form.paid">
                    <b-form-select-option :value="1">Paid</b-form-select-option>
                    <b-form-select-option :value="0"
                      >Un Paid</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col cols="12">
                <b-form-group label="Details *">
                  <b-form-textarea v-model="form.description"></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-footer class="text-right">
            <router-link
              :to="{ path: '/jobs' }"
              tag="span"
              class="btn btn-secondary ml-auto mr-1"
              >Cancel</router-link
            >
            <b-overlay
              :show="loading"
              rounded
              spinner-small
              class="d-inline-block"
            >
              <b-button @click="submit" variant="primary"> Submit </b-button>
            </b-overlay>
          </b-card-footer>
        </b-card>
      </form>
    </div>

    <div class="wrapper fadeInDown" v-if="requests && requests.length > 0">
      <b-card no-body>
        <b-card-header @click="collapsed = !collapsed">
          <h1 class="p-0 m-0">
            Ongoing Requests
            <span class="float-right"><b-icon :icon="'caret-' + (collapsed ? 'up' : 'down') + '-fill'"></b-icon></span>
          </h1>
        </b-card-header>
        <b-card-body v-if="!collapsed">
          <b-row>
            <b-col cols="12">
              <b-table :fields="fields" :items="requests">
                <template #cell(date)="row">
                  {{ row.item.start_date }} {{ row.item.through }}
                </template>
                <template #cell(day)="row">
                  {{ row.item.startday }} {{ row.item.startday != row.item.endday ? '- ' + row.item.endday : '' }}
                </template>
                <template #cell(time)="row">
                  {{ row.item.daypart }}
                </template>
                <template #cell(type)="row">
                  {{ row.item.leavetype }}
                </template>
                <template #cell(status)="row">
                  <span :class="statusClass(row.item.status)">{{ row.item.status }}</span>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>
<script>
import UserService from '@/services/UserService'
import TimeOffService from '@/services/TimeOffService'

import moment from 'moment'

export default {
  data () {
    return {
      loading: false,
      isManager: false,
      employees: [],
      minStart: null,
      form: {
        user_id: null,
        type: 0,
        startdate: '',
        enddate: '',
        daysection: 0,
        paid: 1,
        description: ''
      },

      fields: ['date', 'day', 'time', 'type', 'status'],
      requests: [],
      collapsed: true
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    init () {
      TimeOffService.init().then(
        (response) => {
          if (response.data.isManager && response.data.employees) {
            this.isManager = true
            this.employees = response.data.employees
            const user = UserService.getUserObject()
            this.form.user_id = user.info.id
          }
          if (response.data.requests) {
            this.requests = response.data.requests
          }
        }
      )

      const today = moment().format('YYYY-MM-DD')
      this.form.startdate = today
      this.form.enddate = today

      this.minStart = new Date()
    },

    submit () {
      this.loading = true
      TimeOffService.save(this.form).then(
        (response) => {
          this.$router.push('/', () => {
            this.$notify({
              group: 'notices',
              title: response.data.message,
              type: 'info'
            })
          })
        },
        (error) => {
          this.$notify({
            group: 'errors',
            title: error.response.data.message,
            type: 'warn'
          })
        }
      ).finally(() => { this.loading = false })
    },

    restrictEndDate (ymd, date) {
      return moment(ymd).isBefore(this.form.startdate)
    },

    statusClass (status) {
      if (status === 'Pending') {
        return 'text-warning'
      } else if (status === 'Denied') {
        return 'text-danger'
      } else if (status === 'Approved') {
        return 'text-success'
      } else {
        return ''
      }
    }
  },

  computed: {
    showDay () {
      return moment(this.form.startdate).isSame(this.form.enddate)
    },

    days () {
      return moment(this.form.enddate).diff(moment(this.form.startdate), 'd')
    }
  },

  watch: {
    'form.startdate': function (n, o) {
      if (n !== o) {
        // if (moment(this.form.enddate).isBefore(n)) {
        this.form.enddate = n
        // }
      }
    },
    'form.enddate': function (n, o) {
      if (n !== o) {
        if (!moment(this.form.startdate).isSame(this.form.enddate)) {
          this.form.daysection = 0
        }
      }
    }
  }
}
</script>
