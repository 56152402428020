<template>
  <div class="card mt-3">
    <div class="card-header w-100 p-1 py-2">
      <div class="d-flex h-100 p-0">
        <div class="row align-self-center w-100 p-0 m-0">
          <div class="col">
            <h1 class="p-0 m-0">Totals</h1>
          </div>
          <div class="col text-right">
            &nbsp;
            <a href="#" v-on:click.prevent="expanded = !expanded">
              <span class="fas fa-angle-double-down" v-show="!expanded"></span>
              <span class="fas fa-angle-double-up" v-show="expanded"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- start job list -->
    <div class="card-body p-0 table-responsive">
      <table class="table table-bordered time-stats w-100" v-show="expanded">
        <tr>
          <td>Logged Today:</td>
          <td class="text-right">
            <b>{{ todaysTime }}</b>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="pt-3">
            <b>Current Pay Period ({{ stats.daysremain }} days left)</b>
          </td>
        </tr>
        <tr>
          <td>Logged:</td>
          <td class="text-right">
            <span :class="stats.period > 80 ? 'text-danger' : ''">{{
              stats.period
            }}</span>
          </td>
        </tr>
        <tr>
          <td>This Week:</td>
          <td class="text-right">
            <span :class="stats.currentweektime > 40 ? 'text-danger' : ''">{{
              stats.currentweektime
            }}</span>
          </td>
        </tr>
        <tr>
          <td>End of Day Target:</td>
          <td class="text-right">{{ stats.target }}</td>
        </tr>
        <tr>
          <td>
            <span :class="stats.aimPct > 20 ? 'text-danger' : ''"
              >AIM {{ stats.aimPct }}%:</span
            >
          </td>
          <td class="text-right">{{ stats.aimTime }}</td>
        </tr>
        <tr>
          <td>Lunch/Personal:</td>
          <td class="text-right">{{ stats.breaktime }}</td>
        </tr>

        <tr>
          <td colspan="2" class="pt-3">
            <b>Leave</b>
          </td>
        </tr>
        <tr>
          <td>Vacation/Personal:</td>
          <td class="text-right">{{ stats.leavebalance }}</td>
        </tr>
        <tr>
          <td>EPST (Sick Time):</td>
          <td class="text-right">{{ stats.epstbalance }}</td>
        </tr>
        <tr>
          <td colspan="2" class="pt-3">
            <b>History</b>
          </td>
        </tr>
        <tr>
          <td>Previous Pay Period:</td>
          <td class="text-right">{{ stats.lastperiod }}</td>
        </tr>
        <tr>
          <td>Last Quarter:</td>
          <td class="text-right">{{ stats.lastqtr }}</td>
        </tr>
        <tr>
          <td>Lifetime:</td>
          <td class="text-right">{{ stats.lifetime }}</td>
        </tr>
        <tr>
          <td>Jobs:</td>
          <td class="text-right">{{ stats.jobsLife }}</td>
        </tr>

        <tr>
          <td>Tickets All-Time:</td>
          <td class="text-right">{{ stats.closedTicketsLife }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  props: {
    jobList: {
      default: function () { return [] },
      type: Array
    }
  },
  data () {
    return {
      stats: {},
      expanded: false,
      timer: null,
      refresh: 0
    }
  },
  computed: {
    todaysTime: function () {
      var tot = 0
      // if (this.stats) {
      //   tot += (this.stats.today * 1)
      // }
      var refreshCount = this.refresh
      this.jobList.forEach(x => {
        if (x.starttime !== null && refreshCount > -1) {
          tot += this.getJobTime(x)
        } else {
          tot += (x.totaltime * 1)
        }
      })
      return tot.toFixed(2)
    }
  },
  methods: {
    init: async function () {
      this.$updateloading(1)

      axios.get('api/jobs/summary').then(response => {
        if (response) {
          this.jobTypes = response.data.jobtypes
          this.stats = response.data.stats
          this.techs = response.data.techs
          this.$updateloading(-1)
        }
      }).catch(error => {
        console.log(error)
        this.$updateloading(-1)
      })
    },
    loadJobs: async function () {
      this.$updateloading(1)
      axios.get('api/jobs/my').then(response => {
        if (response) {
          this.jobList = response.data.data
        }
        this.$updateloading(-1)
      }).catch(error => {
        console.dir(error)
      })
    },
    getJobTime: function (job) {
      var t = parseFloat(job.totaltime) || 0
      if (job.starttime) {
        var diff = moment()
        var jStart = moment(job.starttime)
        var diffTime = ((diff.valueOf() - jStart.valueOf()) / (1000 * 60 * 60))
        var cJTime = diffTime
        t += cJTime
      }
      return Math.round(t * 100) / 100
    },
    createInterval: function () {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.timer = setInterval(() => {
        this.refresh = this.refresh + 1
        if (this.refresh >= 100) {
          this.refresh = 0
        }
      }, 36000)
    }
  },
  mounted () {
    this.init()
    this.createInterval()
  },
  destroyed: function () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
</script>
