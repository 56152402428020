<template>
  <b-modal v-model="isShown" size="xl" @hidden="$emit('closed')">
    <template slot="modal-header">
      <h5 class="modal-title">Transfer Ticket</h5>
      <button class="close ml-0" type="button" aria-label="Close" @click="isShown = false">&#xD7;</button>
    </template>
    <div align="center">
      <b-form-group label="Assignee">
        <b-form-select v-model="filters.tech_id" :options="techs">
          <b-select-option :value="null"></b-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-textarea
        id="textarea-large"
        size="lg"
        placeholder="Transfer Note..."
        v-model="filters.text"
      ></b-form-textarea>
    </div>
    <template slot="modal-footer">
      <button class="btn btn-default" @click="isShown = false">
        Close
      </button>
      <button class="btn btn-success" @click="saveTicket()">
        Transfer
        <b-spinner v-if="loading" class="ml-2" small label="Loading..."></b-spinner>
      </button>
    </template>
  </b-modal>
</template>

<script>
import TicketService from '@/services/TicketService'
export default {
  props: {
    value: {
      type: Boolean,
      default () { return false }
    },
    ticketId: {
      type: [Number, String]
    },
    techs: {
      type: Array
    }
  },
  data () {
    return {
      isShown: this.showTransferModal,
      filters: {
        tech_id: null,
        text: null,
        ticket_id: null
      },
      loading: false
    }
  },
  methods: {
    saveTicket: function () {
      this.loading = true
      this.filters.ticket_id = this.ticketId
      TicketService.transferTicket(this.filters).then(
        (response) => {
          if (response.status === 200) {
            this.$emit('closed')
          }
        }
      ).finally(() => { this.loading = false })
    }
  },

  watch: {
    value: function (n) {
      this.isShown = n
    },
    isShown: function (n) {
      this.$emit('input', n)
    }
  }
}
</script>
