<style>
.contactDropMenu {
  width: 100% !important;
}
@media all and (min-width: 992px) {
  .contactDropMenu {
    width: auto !important;
  }
}
</style>
<template>
  <b-container fluid ref="contactList">
    <b-card>
      <b-row class="mb-4">
        <b-col cols="12" md="5">
          <form @submit.prevent="resetPage()" action="">
            <b-form-group>
              <b-input-group prepend="Search">
                <b-input v-model="filters.search"></b-input>
                <b-input-group-append>
                  <b-button type="submit"><b-icon icon="search"></b-icon></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </form>
        </b-col>
        <b-col cols="12" md="3" offset-md="4">
          <!-- <b-button block variant="success"><b-icon icon="plus"></b-icon> Add</b-button> -->
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-overlay
            :show="loading"
          >
            <b-pagination
              v-if="filters.totalItems > filters.max"
              :total-rows="filters.totalItems"
              aria-controls="contactsTable"
              :per-page="filters.max"
              v-model="filters.page"
              @change="changePage"
              align="right"
            ></b-pagination>
            <table class="table b-table contactsTable table-striped b-table-stacked-lg">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Location</th>
                  <th>Client</th>
                  <th>Cell</th>
                  <th>Work</th>
                  <th>Home</th>
                  <th></th>
                </tr>
              </thead>
              <tbody v-if="renderTable">
                <template v-for="(c, k) in contacts">
                  <tr :key="c.id" :aria-details="'_contactDetails_' + k + '_'" :aria-owns="'_contactDetails_' + k + '_'" :class="c._showDetails ? 'b-table-has-details' : ''">
                    <td class="text-center text-lg-left">
                      <div>
                        {{ c.firstname }} {{ c.lastname }}
                        <b-icon class="mx-1" icon="folder2" v-if="+c.activated == 1" v-b-tooltip.hover.top="'Portal User'"></b-icon>
                        <b-icon class="mx-1" icon="check" v-if="+c.portaladmin == 1" v-b-tooltip.hover.top="'Portal Admin'"></b-icon>
                        <b-icon
                          class="mx-1"
                          v-if="+c.activated == 1"
                          :icon="c.lastlogin && c.dayssincelogin && c.dayssincelogin <= 60 ? 'person-check-fill' : 'person-x-fill'"
                          :style="c.lastlogin && c.dayssincelogin && c.dayssincelogin <= 60 ? 'color: green' : 'color: red'"
                          v-b-tooltip.hover.top="'Last login: ' + (c.lastlogin ? c.lastlogin : 'never')"
                        ></b-icon>
                      </div>
                      <small><a :href="'mailto:' + c.email">{{ c.email }}</a></small>
                    </td>
                    <td :class="c.location && c.location.name ? '' : 'd-none d-lg-table-cell'">
                      <b-row>
                        <b-col cols="3" class="d-inline d-lg-none">
                          <b>Location</b>
                        </b-col>
                        <b-col cols="9" lg="12">
                          <span v-if="c.location">
                            {{ c.location.name }}
                          </span>
                        </b-col>
                      </b-row>
                    </td>
                    <td :class="c.company || c.companyname || c.department ? '' : 'd-none d-lg-table-cell'">
                      <b-row>
                        <b-col cols="3" class="d-inline d-lg-none">
                          <b>Client</b>
                        </b-col>
                        <b-col cols="9" lg="12">
                          <div v-if="c.company">
                            <a :href="'/jobs/add?companyid=' + c.company.id">{{ c.companyname ? c.companyname : c.company.name }}</a>
                          </div>
                          <div v-else-if="c.companyname">
                            {{ c.companyname }}
                          </div>
                          <small>
                            {{ c.department }}
                          </small>
                        </b-col>
                      </b-row>
                    </td>
                    <td :class="c.cellphone ? '' : 'd-none d-lg-table-cell'">
                      <b-row>
                        <b-col cols="3" class="d-inline d-lg-none">
                          <b>Cell</b>
                        </b-col>
                        <b-col cols="9" lg="12">
                          <a :href="'tel:' + c.cellphone">{{ c.cellphone | phonenum }}</a>
                        </b-col>
                      </b-row>
                    </td>
                    <td :class="c.workphone ? '' : 'd-none d-lg-table-cell'">
                      <b-row>
                        <b-col cols="3" class="d-inline d-lg-none">
                          <b>Work</b>
                        </b-col>
                        <b-col cols="9" lg="12">
                          <a :href="'tel:' + c.workphone + (c.workext ? ',' + c.workext : '')">
                            {{ c.workphone | phonenum }}<span v-show="c.workext">x{{ c.workext }}</span>
                          </a>
                        </b-col>
                      </b-row>
                    </td>
                    <td :class="c.homephone ? '' : 'd-none d-lg-table-cell'">
                      <b-row>
                        <b-col cols="3" class="d-inline d-lg-none">
                          <b>Home</b>
                        </b-col>
                        <b-col cols="9" lg="12">
                          <a :href="'tel:' + c.homephone">{{ c.homephone | phonenum }}</a>
                        </b-col>
                      </b-row>
                    </td>
                    <td>
                      <!-- <div class="d-inline px-2">
                        <b-icon icon="pencil-square" @click="editContact(c.id)"
                          v-b-tooltip.hover.top="'Update Contact Information'"
                        ></b-icon>
                      </div> -->
                      <!-- <div class="d-inline px-2">
                        <b-icon icon="chat-left-dots" @click="openSendInfo(c)"
                          v-b-tooltip.hover.top="'Send information to tech\'s phone'"
                        ></b-icon>
                      </div> -->
                      <b-dropdown text="Actions" size="sm" block right menu-class="contactDropMenu">
                        <b-dropdown-item @click="toggle(c)" class="small">
                          <b-icon icon="info-circle-fill"></b-icon> {{ c._showDetails ? 'Hide' : 'Show' }} Contact Information
                        </b-dropdown-item>
                        <b-dropdown-item @click="mailmerge(c)" class="small">
                          <b-icon icon="intersect"></b-icon> Mail Merge
                        </b-dropdown-item>
                        <b-dropdown-item @click="printout(c.id)" class="small">
                          <b-icon icon="envelope"></b-icon> Envelope Printout
                        </b-dropdown-item>

                        <b-dropdown-item @click="retire(c.id)" class="small">
                          <b-icon icon="clock-fill"></b-icon> Retire
                        </b-dropdown-item>
                        <b-dropdown-item @click="deleteContact(c.id)" variant="danger" class="small">
                          <b-icon icon="x" style="transform: scale(2); color: red;"></b-icon> DELETE
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                  <tr :key="c.id" v-if="c._showDetails" aria-hidden="true" role="presentation" class="d-none"></tr>
                  <tr :key="c.id" v-if="c._showDetails" :id="'_contactDetails_' + k + '_'" :aria-owns="'_contactDetails_' + k + '_'" tabindex="-1" class="b-table-details" role="row">
                    <td colspan="7">
                      <b-card>
                        <b-row>
                          <b-col cols="12" md="6" class="text-center">
                            <b>Title:</b> {{ c.title }}
                            <div v-if="c.workaddress">
                            <b>Work Address</b><br>
                              <address style="white-space: pre;">
                                <a :href="'//maps.google.com/maps?daddr=' + formatString(c.workaddress)" target="_blank">
                                  {{ c.workaddress }}
                                </a>
                              </address>
                            </div>
                          </b-col>
                          <b-col cols="12" md="6" class="text-center">
                            <div v-if="c.company">
                              <b>Company Phone: </b>
                              <a :href="'tel:' + c.company.phone">{{ c.company.phone | phonenum }}</a>
                            </div>
                            <div v-if="c.homeaddress">
                            <b>Home Address</b><br>
                              <address style="white-space: pre;">
                                <a :href="'//maps.google.com/maps?daddr=' + formatString(c.homeaddress)" target="_blank">
                                  {{ c.homeaddress }}
                                </a>
                              </address>
                            </div>
                            Notes:<br>{{ c.notes }}
                          </b-col>
                        </b-row>
                      </b-card>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <b-pagination
              v-if="filters.totalItems > filters.max"
              :total-rows="filters.totalItems"
              aria-controls="contactsTable"
              :per-page="filters.max"
              v-model="filters.page"
              @change="changePage"
              align="right"
            ></b-pagination>
          </b-overlay>
        </b-col>
      </b-row>
    </b-card>

    <!-- <b-modal
      id="sendToTech"
      @ok="sendContactInfo"
      title="Send to Tech"
      @hidden="resetModal"
    >
      <div v-if="selectedContact">
        <b-form-group label="Tech to send to" label-align="left">
          <b-form-select :options="techs" value-field="id" text-field="name" v-model="sendinfo.tech_id"></b-form-select>
        </b-form-group>

        <b-form-group label="Send" label-align="left">
          <b-form-select v-model="sendinfo.type" @change="sendinfo.note = null">
            <b-form-select-option value="work-address" v-if="selectedContact.workaddress">Work Address</b-form-select-option>
            <b-form-select-option value="home-address" v-if="selectedContact.homeaddress">Home Address</b-form-select-option>
            <b-form-select-option value="email-address" v-if="selectedContact.email">Email Address</b-form-select-option>
            <b-form-select-option value="work-phone" v-if="selectedContact.workphone">Work Phone</b-form-select-option>
            <b-form-select-option value="home-phone" v-if="selectedContact.homephone">Home Phone</b-form-select-option>
            <b-form-select-option value="cell-phone" v-if="selectedContact.cellphone">Cell Phone</b-form-select-option>
            <b-form-select-option value="fax-phone" v-if="selectedContact.faxphone">Fax Phone</b-form-select-option>
            <b-form-select-option value="note">Note</b-form-select-option>
          </b-form-select>
        </b-form-group>

        <b-form-textarea v-if="sendinfo.type == 'note'" v-model="sendinfo.note"></b-form-textarea>
      </div>
    </b-modal> -->
  </b-container>
</template>
<script>
import ContactService from '@/services/ContactService'
import UserService from '@/services/UserService'

export default {
  data () {
    return {
      filters: {
        search: '',
        page: 1,
        max: 15,
        totalItems: 0
      },
      contacts: [],
      techs: [],
      selectedContact: null,
      loadingCount: 0,
      sendinfo: {
        tech_id: null,
        type: null,
        note: null
      },
      user_id: null,
      renderTable: true
    }
  },

  created () {
    this.search()
    // this.getTechs()
  },

  methods: {
    search () {
      this.updatedLoading(1)
      ContactService.search(this.filters).then(
        (response) => {
          this.contacts = response.data.data
          this.filters.totalItems = response.data.meta.total
          this.filters.page = response.data.meta.current_page
        }
      ).finally(() => {
        this.updatedLoading(-1)
        this.$nextTick(() => {
          window.scrollTo(0, 0)
        })
      })
    },

    getTechs () {
      this.updatedLoading(1)
      ContactService.getTechs().then(
        (response) => {
          this.techs = response.data
        }
      ).finally(() => { this.updatedLoading(-1) })

      this.updatedLoading(1)
      const user = UserService.getUser()
      if (user && user.token && user.info.id) {
        this.user_id = user.info.id
        this.sendinfo.tech_id = user.info.id
      }
      this.updatedLoading(-1)
    },

    resetPage () {
      this.filters.page = 1
      this.filters.totalItems = 0
      this.search()
    },

    changePage (page) {
      this.filters.page = page
      this.search()
    },

    updatedLoading (val) {
      if (val > 0) {
        this.loadingCount++
      } else {
        this.loadingCount += val
      }

      if (this.loadingCount < 0) {
        this.loadingCount = 0
      }
    },

    editContact (id) {
      console.log(id)
    },

    printout (id) {
      window.open('/envelope/' + id, '_blank')
    },

    mailmerge (data) {
      this.updatedLoading(1)
      ContactService.mailmerge(data.id).then(
        () => {
          this.$notify({
            group: 'notices',
            title: data.firstname + ' ' + data.lastname + ' ready for mail merge',
            type: 'info'
          })
        }
      ).finally(() => { this.updatedLoading(-1) })
    },

    retire (id) {
      this.$bvModal.msgBoxConfirm('Are you sure?').then(
        (val) => {
          if (val) {
            this.updatedLoading(1)
            ContactService.retire(id).then(
              (response) => {
                this.$notify({
                  group: 'notices',
                  title: 'Contact retired',
                  type: 'info'
                })
                this.search()
              }
            ).finally(() => { this.updatedLoading(-1) })
          }
        }
      )
    },

    deleteContact (id) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete?',
        {
          okTitle: 'DELETE',
          okVariant: 'danger'
        }
      ).then(
        (val) => {
          if (val) {
            this.updatedLoading(1)
            ContactService.delete(id).then(
              (response) => {
                this.$notify({
                  group: 'notices',
                  title: 'Contact deleted',
                  type: 'info'
                })
                this.search()
              }
            ).finally(() => { this.updatedLoading(-1) })
          }
        }
      )
    },

    openSendInfo (data) {
      this.selectedContact = data
      if (this.user_id) {
        this.sendinfo.tech_id = this.user_id
      }
      this.$bvModal.show('sendToTech')
    },

    sendContactInfo (bvModalEvt) {
      bvModalEvt.preventDefault()
      ContactService.sendContact(this.selectedContact.id, this.sendinfo).then(
        (response) => {
          this.$nextTick(() => {
            this.$bvModal.hide('sendToTech')
          })
        }
      )
    },

    toggle (c) {
      c._showDetails = !c._showDetails

      this.renderTable = false

      this.$nextTick(() => { this.renderTable = true })
    },

    resetModal () {
      this.selectedContact = null
      this.sendinfo = {
        tech_id: null,
        type: null,
        note: null
      }
    },

    formatString (str) {
      return str.replace(/\r?\n|\r/g, ' ').replace(/\s+/g, ' ')
    }
  },

  computed: {
    loading () {
      return this.loadingCount > 0
    }
  },

  filters: {
    phonenum (val) {
      // code from https://learnersbucket.com/examples/javascript/how-to-format-phone-number-in-javascript/
      const cleaned = ('' + val).replace(/\D/g, '')
      const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        const intlCode = (match[1] ? '+1 ' : '')
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
      }
      return ''
    }
  }
}
</script>
<style>
  .contactsTable td, .contactsTable th {
    font-size: 0.8rem !important;
  }

  .contactsTable td {
    padding: 1em 0.5em !important;
    vertical-align: middle !important;
  }
</style>
