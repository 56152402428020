<template>
  <b-modal v-model="isShown" size="xl" @hidden="$emit('closed')">
    <template slot="modal-header">
      <h5 class="modal-title">Ticket Details</h5>
      <b-button-group class="float-right ml-auto">
        <button class="btn btn-warning" @click="editTicket()"
          v-b-tooltip.hover title="Edit Ticket"
        ><b-icon icon="pencil-square"></b-icon></button>
        <router-link
          :to="'/jobs/add?ticket_id=' + ticketId"
          class="btn btn-primary"
          v-b-tooltip.hover title="Start Job"
          ><b-icon icon="stopwatch"></b-icon></router-link
        >
      </b-button-group>
      <button class="close ml-0" type="button" aria-label="Close" @click="isShown = false">&#xD7;</button>
    </template>
    <TicketDetail :id="ticketId"></TicketDetail>
    <template slot="modal-footer">
      <button class="btn btn-default" @click="isShown = false">
        Close Details
      </button>
    </template>
  </b-modal>
</template>

<script>
import TicketDetail from '@/components/tickets/Detail'
export default {
  components: { TicketDetail },
  props: {
    value: {
      type: Boolean,
      default () { return false }
    },
    ticketId: {
      type: [Number, String]
    }
  },
  data () {
    return {
      isShown: this.showDetailModal
    }
  },
  methods: {
    editTicket: function () {
      this.$router.push('/tickets/edit/' + this.ticketId)
    }
  },
  watch: {
    value: function (n) {
      this.isShown = n
    },
    isShown: function (n) {
      this.$emit('input', n)
    }
  }
}
</script>
