<template>
  <div id="app" class="container-fluid">
    <notifications group="warnings" />
    <notifications group="errors" />
    <notifications group="notices" />
    <NavBar v-if="!$route.meta.printout"></NavBar>
    <router-view />
    <div
      class="card mt-3 mb-2 fadeInDown"
      v-if="
        timeoff.today.length > 0 ||
        timeoff.remote.length > 0 ||
        timeoff.future.length > 0
      "
    >
      <div class="row p-4">
        <div
          class="
            col-xs-12 col-lg-4
            text-danger
            mb-4 mb-lg-0
            text-center text-lg-left
          "
        >
          <div v-for="u in timeoff.today" :key="u.id">
            {{ u.pasttoday }} {{ u.daypart }}
            {{ u.firstname + " " + u.lastname }} {{ u.leavetype }}
          </div>
        </div>
        <div
          class="
            col-xs-12 col-lg-4
            text-muted
            mb-4 mb-lg-0
            text-center
          "
        >
          <div v-for="u in timeoff.remote" :key="u.id">
            {{ u.firstname + " " + u.lastname }} ({{ u.shifttype }})
          </div>
        </div>
        <div
          class="
            col-xs-12 col-lg-4
            text-warning
            mb-4 mb-lg-0
            text-right
          "
        >
          <div v-for="u in timeoff.future" :key="u.id">
            {{ u.pasttoday }} {{ u.daypart }}
            {{ u.firstname + " " + u.lastname }} {{ u.leavetype }}
          </div>
        </div>
      </div>
    </div>
    <!-- already included via main.js -->
    <loader-overlay></loader-overlay>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import axios from 'axios'
import UserService from '@/services/UserService'
import TimeOffService from './services/TimeOffService'

export default {
  components: { NavBar },
  data () {
    return {
      timeoff: {
        today: [],
        future: [],
        remote: []
      }
    }
  },
  methods: {
    getTimeOff: function () {
      this.timeoff = {
        today: [],
        future: [],
        remote: []
      }
      const user = UserService.getUserObject()
      if (user && user.token) {
        TimeOffService.listOthers().then(
          (response) => {
            this.timeoff = response.data
          }
        )
      }
    },

    async setupAxios () {
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      axios.defaults.headers.get['Content-Type'] = 'application/json'
      axios.defaults.headers.get.Accept = 'application/json'
      axios.defaults.headers.post.Accept = 'application/json'
      axios.defaults.withCredentials = true
      if (process.env.NODE_ENV === 'development') {
        axios.defaults.params = {}
        axios.defaults.params.XDEBUG_SESSION = 'mobile'
      }
      // always add token to axios config for ease
      axios.interceptors.request.use(async function (config) {
        const token = await UserService.authHeader()
        const baseUrl = UserService.baseUrl()
        if (token) {
          config.headers.Authorization = `${token}`
        }
        config.baseURL = baseUrl
        return config
      }, function (err) {
        return Promise.reject(err)
      })
      // intercept all the axios request
      axios.interceptors.response.use(
        // handle response here
        (response) => {
          return Promise.resolve(response)
        },
        (err) => {
          if (typeof err.response === 'undefined') {
            throw new Error('An internal error has occurred.')
          } else {
            const unAuthErrorCode = [401]
            if (unAuthErrorCode.includes(err.response.status)) {
              UserService.logout()
              this.$notify({
                group: 'errors',
                title: 'Session expired',
                text: 'You must login to continue.',
                type: 'warn'
              })
              this.$router.push('/login')
              Promise.reject(err.response)
            } else if (err.response.status === 425) {
              // efish service suspended or cancelled
              this.$router.push('/suspended')
            } else {
              throw err
            }
          }
        })
    }
  },
  created () {
    this.setupAxios()
    this.getTimeOff()
  },

  watch: {
    '$route' (to, from) {
      if (to !== from) {
        this.getTimeOff()
      }
    }
  }
}
</script>

<style lang="sass">
@import "sass/_variables.scss"
</style>

<style>
/* Fixed header spacing */
#app {
  margin-top: 56px;
}

/* BASIC */
body {
  font-family: "Poppins", sans-serif;
  font-size: 0.75rem;
}

a {
  color: #ff0000;
  text-decoration: none;
  font-weight: 400;
}

a:hover,
a:active {
  text-decoration: none;
}

h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  margin: 40px 8px 10px 8px;
  color: #cccccc;
}

/* STRUCTURE */

.img-wrap {
  margin: 55px 0;
}

.wrapper {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 7px 2px 15px;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 90%;
  margin: auto;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center !important;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

/* TABS */

h2.inactive {
  color: #cccccc;
}

h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}

/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
/* .underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after {
  width: 100%;
} */

/* OTHERS */

*:focus {
  outline: none;
}

#icon {
  width: 45%;
}

/*Sorting icons*/
.sort-icon-both::after {
  content: url("/static/img/icons/sort10.png");
}
.sort-icon-up::after {
  content: url("/static/img/icons/sort-up10.png");
}
.sort-icon-down::after {
  content: url("/static/img/icons/sort-down10.png");
}

/*
*/

.frozen-table th:first-child,
.frozen-table td:first-child {
  position: sticky;
  left: 0px;
  top: 0px;
}
.frozen-table th:first-child {
  background-color: white;
}
.frozen-table td:first-child {
  background-color: inherit;
}
#app {
  min-height: calc(85vh);
}
/* .card {
  border-width: 0px;
} */
.card-header h1 {
  font-size: 1.5rem;
}
.ng-spinner i {
  color: #3d3d3d;
}
.list-group {
  margin-bottom: 15px;
}
body {
  background-color: #3d3d3d;
}
.navbar-toggler {
  font-size: 1rem;
}
@media screen and (max-width: 450px) {
  .col-xl,
  .col-xl-auto,
  .col-xl-12,
  .col-xl-11,
  .col-xl-10,
  .col-xl-9,
  .col-xl-8,
  .col-xl-7,
  .col-xl-6,
  .col-xl-5,
  .col-xl-4,
  .col-xl-3,
  .col-xl-2,
  .col-xl-1,
  .col-lg,
  .col-lg-auto,
  .col-lg-12,
  .col-lg-11,
  .col-lg-10,
  .col-lg-9,
  .col-lg-8,
  .col-lg-7,
  .col-lg-6,
  .col-lg-5,
  .col-lg-4,
  .col-lg-3,
  .col-lg-2,
  .col-lg-1,
  .col-md,
  .col-md-auto,
  .col-md-12,
  .col-md-11,
  .col-md-10,
  .col-md-9,
  .col-md-8,
  .col-md-7,
  .col-md-6,
  .col-md-5,
  .col-md-4,
  .col-md-3,
  .col-md-2,
  .col-md-1,
  .col-sm,
  .col-sm-auto,
  .col-sm-12,
  .col-sm-11,
  .col-sm-10,
  .col-sm-9,
  .col-sm-8,
  .col-sm-7,
  .col-sm-6,
  .col-sm-5,
  .col-sm-4,
  .col-sm-3,
  .col-sm-2,
  .col-sm-1,
  .col,
  .col-auto,
  .col-12,
  .col-11,
  .col-10,
  .col-9,
  .col-8,
  .col-7,
  .col-6,
  .col-5,
  .col-4,
  .col-3,
  .col-2,
  .col-1 {
    position: relative;
    width: 100%;
    padding-right: 5px;
    padding-left: 5px;
  }
  .container-fluid {
    padding-left: 5px;
    padding-right: 5px;
  }
  .row {
    margin-left: -5px;
    margin-right: -5px;
  }
}
td,
th {
  font-size: 0.7rem;
}
.table thead th {
  border-top: 0px;
}
.table th,
.table td {
  padding: 0.3rem;
}
tr:last-child td {
  border-bottom: 2px solid #dee2e6;
}
.list-group-item-heading a {
  color: #ffffff !important;
  text-decoration: none;
}

.icon-button {
  padding: 3px !important;
  line-height: 1rem;
  border-radius: 50% !important;
}
.description-link {
  color: blue !important;
  cursor: pointer;
  text-decoration: underline;
}

.bad_link {
  text-decoration: line-through;
}
</style>
