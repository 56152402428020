<template>
  <span>
    <b-modal v-model="visible" v-bind:title="'Delete Ticket ' + job.id">
      <div>Are you sure you want to delete this job?</div>
      <template v-slot:modal-footer>
        <button class="btn btn-secondary" @click="cancel()">Cancel</button>
        <button class="btn btn-primary" @click="deleteJob()">Delete</button>
      </template>
    </b-modal>
    <b-modal
      v-model="errorFeedback"
      title="Unable to Delete Ticket"
      :ok-only="true"
      @ok="errorFeedback = false"
    >
    </b-modal>
  </span>
</template>

<script>
import axios from 'axios'
import UserService from '@/services/UserService'

export default {
  props: {
    display: {
      type: Number,
      default () {
        return 0
      }
    },
    job: {
      type: Object
    }
  },
  computed: {
    /**
     * @returns {Boolean}
     */
    visible: function () {
      return this.display > 0
    }
  },
  data () {
    return {
      confirm: null,
      errorFeedback: false
    }
  },
  methods: {
    cancel: function () {
      this.$emit('cancel')
    },
    deleteJob: function () {
      axios({
        url: UserService.baseUrl() + 'api/jobs/' + this.job.id,
        method: 'DELETE'
      }).then(response => {
        this.$notify({
          group: 'notices',
          title: 'Job Deleted',
          type: 'info'
        })
        this.$emit('job-deleted')
      }, error => {
        console.log(error)
        if (error.response.status === 404) {
          this.errorFeedback = 'Invalid Job ID. The job does not exist.'
        } else {
          this.errorFeedback = error.response.data.errormessages
        }
      })
    }
  }
}
</script>
