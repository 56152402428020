<template>
  <div
    v-show="loading > 0"
    class="loader-container"
  >
    <div class="loader-content">
      <div class="ng-loader">
        <i class="fa-5x fas fa-spinner fa-pulse"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { events } from './events'
export default {
  data () {
    return {
      loading: 0
    }
  },
  methods: {
    changeLoadingValue (value) {
      this.loading += value
      if (this.loading < 0) {
        this.loading = 0
      }
    }
  },
  mounted () {
    events.$on('updateloading', this.changeLoadingValue)
  }
}
</script>

<style scoped>
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.5;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  filter: alpha(opacity=50);
  z-index: 100000;
}
.loader-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
