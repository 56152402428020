<template>
  <div class="card">
    <form @submit.prevent="save()" action="" ref="ticketForm">
      <div class="card-header">
        <div v-show="id > 0">
          <div class="h4">Edit Ticket {{ id }}</div>
          <div class="small">Submitted: {{ form.submitted }}</div>
        </div>
        <div class="h4" v-show="id == 0">Add Ticket</div>
      </div>
      <div class="card-body p-2">
        <div class="card">
          <div class="card-header">Company/Requester</div>
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-12 form-group">
                <company-type-ahead-selector
                  :selectedCompanyId="form.company_id"
                  @select-company="selectCompany"
                  :isValid="valid.company_id"
                  required
                ></company-type-ahead-selector>
              </div>
            </div>

            <div class="row align-items-center">
              <div class="col-12 col-md-4 form-group">
                <label class="form-label" for="requester">Requester</label>
                <div class="input-group">
                  <b-form-select
                    v-model="form.contact_id"
                    :options="requesters"
                    @change="updateContact($event)"
                    v-if="selectRequester"
                    :disabled="!form.company_id"
                    :state="valid.contact_id === false ? false : null"
                    required
                  >
                    <template #first>
                      <b-select-option :value="null">
                        -- Select One --
                      </b-select-option>
                    </template>
                  </b-form-select>
                  <b-form-input
                    v-model="form.requester"
                    v-else
                    :state="valid.contact_id === false ? false : null"
                    @input="valid.contact_id = true"
                    required
                  ></b-form-input>
                  <div class="input-group-append">
                    <button
                      type="button"
                      class="btn btn-outline-secondary"
                      @click.prevent="switchRequesterField()"
                    >
                      <i v-if="selectRequester" class="fas fa-pencil-alt"></i>
                      <i v-else class="fas fa-list-ul"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4 form-group">
                <label class="form-label">Phone</label>
                <input
                  class="form-control"
                  v-model="form.alternatephone"
                />
              </div>
              <div class="col-12 col-md-4 form-group">
                <label class="form-label">Email</label>
                <input
                  class="form-control"
                  v-model="form.email"
                />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-6">
                <b-form-checkbox
                  v-model="form.emailnotification"
                  class="text-danger"
                  :unchecked-value="0"
                  :value="1"
                >
                  Notify
                </b-form-checkbox>
              </div>
              <div class="col-6 text-right">
                <b-form-checkbox
                  v-model="form.unpublished"
                  class="text-danger"
                  title="Hides From Support Portal"
                >
                  DO NOT Publish
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
        <!-- Details, description, private, public notes -->
        <div class="card">
          <div class="card-header">Details</div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <label class="form-label">Description</label>
                <textarea
                  class="form-control"
                  :class="valid.description ? 'error' : ''"
                  v-model="form.description"
                  required
                ></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label class="form-label">Notes/Direction (public)</label>
                <textarea
                  class="form-control"
                  v-model="form.delegatornotes"
                ></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label class="form-label">Private Info</label>
                <textarea
                  class="form-control"
                  v-model="form.privateinfo"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header">Types</div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-4">
                <label for="tickettype">Type</label>
                <b-form-select
                  v-model="form.tickettype_id"
                  :options="types"
                  :disabled="!form.company_id"
                  id="tickettype"
                  :state="valid.tickettype_id === false ? false : null"
                  @change="valid.tickettype_id = true"
                  required
                >
                  <template #first>
                    <b-select-option :value="null">
                      -- Select One --
                    </b-select-option>
                  </template>
                </b-form-select>
              </div>
              <div class="col-12 col-md-4">
                <label for="source">Source</label>
                <b-form-select
                  v-model="form.source"
                  :options="sources"
                  id="source"
                >
                </b-form-select>
              </div>
              <div class="col-12 col-md-4">
                <label for="project">Project</label>
                <b-form-select
                  :options="projects"
                  value-field="id"
                  text-field="name"
                  v-model="form.project_id"
                  :disabled="!form.company_id"
                  id="project"
                >
                  <template #first>
                    <b-select-option :value="null">
                      -- Select --
                    </b-select-option>
                  </template>
                </b-form-select>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">Assignment</div>
          <div class="card-body">
            <div class="row align-items-end">
              <div class="col-12 col-md-4">
                <label>Assign</label>
                <b-form-select v-model="form.tech_id" :options="techs">
                </b-form-select>
              </div>
              <div class="col-12 col-md-4">
                <label>Estimated Hours</label>
                <input
                  class="form-control"
                  type="number"
                  step="0.25"
                  v-model="form.estimatedtime"
                  required
                />
              </div>
              <div class="col-12 col-md-4">
                <b-form-checkbox
                  v-model="form.urgency"
                  class="form-control"
                  style="border: none; color: red"
                  :unchecked-value="0"
                  :value="1"
                >
                  <b>Urgent</b> (page tech)
                </b-form-checkbox>
              </div>
            </div>
            <div
              class="row"
              v-if="form.id > 0 && form.tech_id != original.tech_id"
            >
              <div class="col-12">
                <label class="form-label">Transfer Note</label>
                <textarea
                  class="form-control"
                  v-model="form.transfernote"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">Dates</div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-12">
                <label>Timeframe</label>
                <b-form-select
                  :options="timeframes"
                  v-model="userrequesttimeframe"
                  @change="selectTimeFrame($event, 'promised')"
                >
                  <template #first>
                    <b-select-option :value="null" disabled> </b-select-option>
                  </template>
                </b-form-select>
                <span v-show="userrequesttimeframe.warning">
                  {{ userrequesttimeframe.warning }}
                </span>
              </div>
            </div>
            <date-time-picker-options
              :date="form.promised.date"
              :time="form.promised.time"
              :options="dayOptions('promised')"
              :timeoptions="timesOptions(6, 18, true)"
              @setDate="selectDateTime($event, 'promised', 'date')"
              @setTime="selectDateTime($event, 'promised', 'time')"
              @resetState="valid.promised = true"
              label="Promised"
              :state="valid.promised"
            ></date-time-picker-options>
            <date-time-picker-options
              :date="form.deadline.date"
              :time="form.deadline.time"
              :options="dayOptions('deadline')"
              :timeoptions="timesOptions(6, 18, false)"
              @setDate="selectDateTime($event, 'deadline', 'date')"
              @setTime="selectDateTime($event, 'deadline', 'time')"
              @resetState="valid.deadline = true"
              label="Deadline"
              :state="valid.deadline"
            ></date-time-picker-options>
          </div>
        </div>
        <div class="card">
          <div class="card-header">Files</div>
          <div class="card-body">
            <div class="row mb-2" v-for="file in existingFiles" :key="file.id">
              <div class="col-12">
                <a
                  :href="file.link"
                  class="mr-4"
                  :class="
                    form.filesToDelete.includes(file.id) ? 'bad_link' : ''
                  "
                  >{{ file.name }}</a
                >
                <b-button
                  variant="danger"
                  class="icon-button"
                  @click="form.filesToDelete.push(file.id)"
                  v-if="!form.filesToDelete.includes(file.id)"
                >
                  <b-icon icon="dash-circle"></b-icon>
                </b-button>
                <b-button
                  variant="success"
                  class="icon-button mr-2"
                  v-else
                  @click="addFileBack(file.id)"
                >
                  <b-icon icon="plus-circle"></b-icon>
                </b-button>
              </div>
            </div>
            <div class="row mb-2" v-for="(file, k) in form.files" :key="k">
              <div class="col-9 col-md-6">
                <b-form-file
                  v-model="form.files[k]"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                ></b-form-file>
              </div>
              <div class="col-3">
                <b-button
                  variant="success"
                  class="icon-button mr-2"
                  v-if="k == 0"
                  @click="form.files.push(null)"
                >
                  <b-icon icon="plus-circle"></b-icon>
                </b-button>
                <b-button
                  variant="danger"
                  class="icon-button"
                  @click="removeFile(k)"
                  v-if="k != 0 || Boolean(file)"
                >
                  <b-icon icon="dash-circle"></b-icon>
                </b-button>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header">Set Reminder</div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <b-form-checkbox
                  v-model="form.remindMe"
                  class="form-control"
                  style="border: none"
                  :unchecked-value="0"
                  :value="1"
                >
                  Remind me instead of Selected Tech
                </b-form-checkbox>
              </div>
            </div>

            <date-time-picker-options
              :date="form.reminder.date"
              :time="form.reminder.time"
              :options="dayOptions('reminder')"
              :timeoptions="timesOptions(6, 18, true)"
              @setDate="selectDateTime($event, 'reminder', 'date')"
              @setTime="selectDateTime($event, 'reminder', 'time')"
              label="Reminder"
            ></date-time-picker-options>

            <div class="row">
              <div class="col-12">
                <label class="form-label">Note</label>
                <textarea
                  class="form-control"
                  v-model="form.remindNote"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer w-100 p-1 py-2">
        <div class="d-flex h-100 p-0">
          <router-link
            :to="{ path: '/jobs' }"
            tag="span"
            class="btn btn-secondary ml-auto mr-1"
          >
            Cancel
          </router-link>
          <div v-if="this.form.id > 0">
            <button class="btn btn-primary" type="submit">Update</button>
          </div>
          <div v-else>
            <button class="btn btn-primary" type="submit">Save</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import axios from 'axios'
import TicketService from '@/services/TicketService'
import moment from 'moment'
import DateTimePickerOptions from '../form/element/DateTimePickerOptions.vue'
import CompanyTypeAheadSelector from '../form/element/CompanyTypeAheadSelector.vue'

export default {
  components: {
    DateTimePickerOptions,
    CompanyTypeAheadSelector
  },
  props: {
    id: {
      type: [Number, String],
      default: 0
    }
  },
  data () {
    return {
      form: {
        submitted: null,
        company_id: null,
        contact_id: null,
        requester: null,
        unpublished: false,
        alternatephone: null,
        email: null,
        emailnotification: 1,
        description: null,
        tickettype_id: null,
        source: 'internal',
        tech_id: null,
        urgency: 0,
        delegatornotes: null,
        privateinfo: null,
        files: [null],
        promised: {
          date: null,
          time: null
        },
        deadline: {
          date: null,
          time: null
        },
        estimatedtime: '1.00',
        project: null,
        remindMe: 0,
        reminder: {
          date: null,
          time: null
        },
        remindNote: null,
        filesToDelete: [],
        userrequesttimeframe_id: null,
        project_id: null
      },
      // store the original data when editing
      original: {},
      existingFiles: [],
      selectRequester: true,
      selectedTimeFrame: null,
      selectedDeadline: null,
      companySearch: '',
      internalRequesters: [],
      companyRequesters: [],
      companies: [],
      requesters: [],
      types: [],
      sources: [],
      techs: [],
      timeframes: [],
      userrequesttimeframe: {},
      projects: [],
      company: null,
      datetimes: {
        deadline: {
          date: null,
          time: null
        },
        promised: {
          date: null,
          time: null
        },
        remindDate: {
          date: null,
          time: null
        }
      },
      valid: {
        company_id: true,
        contact_id: true,
        email: true,
        tickettype_id: true,
        promised: true,
        deadline: true
      }
    }
  },

  mounted () {
    this.form.submitted = moment().format('YYYY-MM-DD HH:mm:ss')
    this.loadData()
  },

  methods: {
    loadData: async function () {
      const urlParams = new URLSearchParams(window.location.search)
      const selectCompanyId = urlParams.get('company_id')
      if (selectCompanyId > 0) {
        this.selectCompany(+selectCompanyId)
      }
      return Promise.all(
        [
          this.addInit(),
          this.getTicket()
        ]
      )
    },

    addInit: async function () {
      this.$updateloading(1)
      TicketService.addInit().then(
        (response) => {
          this.internalRequesters = []
          response.data.requesters.forEach(e => {
            this.internalRequesters.push({ value: e.id, text: e.name_first + ' ' + e.name_last })
          })
          // if I load the requester options here when editing a ticket, things go wrong
          if (+this.id === 0) {
            if (this.companyRequesters.length > 0) {
              this.requesters.push({ label: 'Client Contacts', options: this.companyRequesters })
            }
            if (this.internalRequesters.length > 0) {
              this.requesters.push({ label: 'Internal', options: this.internalRequesters })
            }
          }
          this.sources = response.data.sources
          this.techs = response.data.techs
          this.timeframes = response.data.timeframes

          return Promise.resolve(true)
        }
      ).finally(() => { this.$updateloading(-1) })
    },

    splitDate: function (info) {
      if (info === null) {
        return {
          date: null,
          time: null
        }
      } else {
        const promised = moment(info)
        return {
          date: promised.format('YYYY-MM-DD'),
          time: promised.format('HH:mm:ss')
        }
      }
    },
    getTicket: async function () {
      if (+this.id > 0) {
        this.$updateloading(1)
        TicketService.get(this.id).then(
          (response) => {
            const info = response.data
            info.promised = this.splitDate(info.promised)
            info.deadline = this.splitDate(info.deadline)
            info.reminder = this.splitDate(info.reminder)
            info.email = info.requester.email
            info.requester = info.requester.name

            if (info.contact_id > 0) {
              this.selectRequester = true
            }
            this.existingFiles = info.files
            info.files = [null]
            info.filesToDelete = []

            this.company = info.company
            this.form = info
            if (!this.form.project_id || isNaN(this.form.project_id) || +this.form.project_id <= 0) {
              this.form.project_id = null
            }
            this.original = Object.assign({}, info)

            if (!this.form.contact_id) {
              this.selectRequester = false
            }

            this.loadCompany()
            this.loadTicketTypes()
            this.getProjects()
            return Promise.resolve(true)
          }, (error) => {
            if (error.response.status === 404) {
              this.$notify({
                group: 'errors',
                title: 'Ticket Not Found',
                text: 'That ticket does not exist.',
                type: 'warn'
              })
              this.$router.push('/tickets/add')
            }
          }
        ).finally(() => { this.$updateloading(-1) })
      } else {
        return Promise.resolve(true)
      }
    },

    selectCompany: function (comp) {
      if (comp === null) {
        this.clearCompany()
        return
      }
      // when the company-select component returns a selection, it will be an object
      if (typeof comp === 'object') {
        this.form.company_id = comp.id
        this.company = comp
      } else {
        // just an int, from the URL probably
        // the company-select component will still end up re-calling this method
        // when it emits the selection
        this.form.company_id = comp
      }

      this.form.contact_id = 0
      // this.form.alternatephone = null
      // this.form.email = null
      this.loadCompany()
      this.loadTicketTypes()
      this.getProjects()
    },

    loadCompany: async function () {
      if (!this.form.company_id) {
        return false
      }

      this.$updateloading(1)
      axios.get('api/companies/' + this.form.company_id + '?context=jobAddEdit')
        .then(res => {
          this.company.contacts = res.data.contacts
          this.companyRequesters = []
          this.company.contacts.forEach(e => {
            this.companyRequesters.push({
              value: e.id,
              text: e.firstname + ' ' + e.lastname
            })
          })
          this.requesters = []
          if (this.companyRequesters.length > 0) {
            this.requesters.push({ label: 'Client Contacts', options: this.companyRequesters })
          }
          if (this.internalRequesters.length > 0) {
            this.requesters.push({ label: 'Internal', options: this.internalRequesters })
          }
        })
        .catch(err => {
          console.log(err)
        }).finally(() => {
          this.$updateloading(-1)
        })
    },

    loadTicketTypes: async function () {
      this.$updateloading(1)
      axios.get('api/tickets/tickettypes/' + this.form.company_id)
        .then(res => {
          let currCat = ''
          this.types = []
          const items = []
          res.data.forEach(e => {
            if (e.category !== currCat) {
              items.push({ label: e.category, options: [] })
              currCat = e.category
            }
            items[items.length - 1].options.push({ value: e.id, text: e.description })
          })

          items.forEach(e => {
            this.types.push(e)
          })
        })
        .catch(err => {
          console.log(err)
        }).finally(() => {
          this.$updateloading(-1)
        })
    },

    getProjects: async function () {
      this.$updateloading(1)
      axios.get('api/projects/?company_id=' + this.form.company_id)
        .then(res => {
          this.projects = res.data
        })
        .catch(err => {
          console.log(err)
        }).finally(() => {
          this.$updateloading(-1)
        })
    },

    clearCompany () {
      this.form.company_id = null
      this.company = null

      this.form.contact_id = null
      this.form.alternatephone = null
      this.form.email = null
      this.form.project = null
      this.requesters = []
      this.projects = []
      if (this.internalRequesters.length > 0) {
        this.requesters.push({ label: 'Internal', options: this.internalRequesters })
      }
    },

    switchRequesterField: function () {
      this.selectRequester = !this.selectRequester
      this.form.requester = null
      this.form.contact_id = null
      this.form.alternatephone = null
      this.form.email = null
    },

    updateContact: function (user) {
      this.valid.contact_id = true
      this.valid.email = true
      axios.get('api/tickets/contactinfo/' + user).then(
        (res) => {
          this.form.alternatephone = res.data.phone
          this.form.email = res.data.email
        }
      )
    },

    selectTimeFrame: function (val, item) {
      const parts = val.datetime.split(/ /)
      this.form[item].date = parts[0]
      this.form[item].time = parts[1]
      this.form.userrequesttimeframe_id = val.id
    },
    selectDateTime: function (val, item, section) {
      if (section === 'date') {
        this.form[item].date = val
        if (val === null) {
          // clear the time
          this.form[item].time = null
        } else if (this.form[item].time === null) {
          // no time set yet, default it for convenience
          this.form[item].time = '17:00:00'
        }
      }

      if (section === 'time') {
        this.form[item].time = val
      }
    },

    setEstTime: function (val) {
      if (val !== null) {
        this.form.estimatedtime = val
      }
    },

    removeFile: function (index) {
      this.form.files.splice(index, 1)
      if (this.form.files.length === 0) {
        this.form.files.push(null)
      }
    },

    addFileBack: function (id) {
      const index = this.form.filesToDelete.indexOf(id)
      console.log(id)
      console.log(index)
      if (index > -1) {
        this.form.filesToDelete.splice(index, 1)
      }
    },

    timesOptions: function (start, end, half = false) {
      const times = []

      for (let i = start; i <= end; i++) {
        const ampm = ((i / 12) >= 1) ? 'pm' : 'am'
        const twelveHr = ((i % 12) === 0) ? 12 : (i % 12)
        times.push({
          value: i + ':00:00',
          text: twelveHr + ':00 ' + ampm
        })
        if (i !== end && half) {
          times.push({
            value: i + ':30:00',
            text: twelveHr + ':30 ' + ampm
          })
        }
      }
      return times
    },

    dayOptions: function (type) {
      const options = [
        { value: moment().format('YYYY-MM-DD'), text: 'Today' },
        { value: moment().add(1, 'd').format('YYYY-MM-DD'), text: 'Tomorrow' },
        { value: moment().add(2, 'd').format('YYYY-MM-DD'), text: '2 Days' },
        { value: moment().add(3, 'd').format('YYYY-MM-DD'), text: '3 Days' },
        { value: moment().add(4, 'd').format('YYYY-MM-DD'), text: '4 Days' }
      ]
      if (type === 'deadline') {
        options.push(
          { value: moment().add(5, 'd').format('YYYY-MM-DD'), text: '5 Days' },
          { value: moment().add(6, 'd').format('YYYY-MM-DD'), text: '6 Days' }
        )
      }
      options.push(
        { value: moment().add(1, 'w').format('YYYY-MM-DD'), text: '1 Week' },
        { value: moment().add(2, 'w').format('YYYY-MM-DD'), text: '2 Weeks' }
      )
      if (type === 'deadline') {
        options.push({ value: moment().add(3, 'w').format('YYYY-MM-DD'), text: '3 Weeks' })
      }
      options.push({ value: moment().add(1, 'M').format('YYYY-MM-DD'), text: '1 Month' })
      if (type === 'deadline') {
        options.push({ value: moment().add(2, 'M').format('YYYY-MM-DD'), text: '2 Months' })
      }
      options.push({ value: moment().add(3, 'M').format('YYYY-MM-DD'), text: '3 Months' })
      return options
    },

    save: function () {
      this.$updateloading(1)
      if (+this.id > 0) {
        TicketService.update(this.id, this.form).then(
          (response) => {
            response.data.ticket_id = this.id
            this.uploadFiles(response.data)
          },
          (err) => {
            this.displayErrorMessages(err.response.data)
          }
        ).finally(() => {
          this.$updateloading(-1)
        })
      } else {
        TicketService.save(this.form).then(
          (response) => {
            if (response.data.ticket_id) {
              this.uploadFiles(response.data)
            }
          },
          (err) => {
            this.displayErrorMessages(err.response.data)
          }
        ).finally(() => {
          this.$updateloading(-1)
        })
      }
    },

    uploadFiles: async function (data) {
      this.$updateloading(1)
      var formData = new FormData()
      let index = 0
      this.form.files.forEach(el => {
        if (el) {
          formData.append('file' + index, el)
          index++
        }
      })
      if (index > 0) {
        TicketService.attachFiles(data.ticket_id, formData).finally(
          () => {
            this.$updateloading(-1)
          })
          .finally(() => {
            this.$router.push('/jobs', () => {
              this.$notify({
                group: 'notices',
                title: 'Ticket saved',
                text: data.message,
                type: 'danger'
              })
            })
          })
      } else {
        this.$updateloading(-1)

        this.$router.push('/jobs', () => {
          this.$notify({
            group: 'notices',
            title: 'Ticket saved',
            text: data.message,
            type: 'danger'
          })
        })
      }
    },

    displayErrorMessages: function (data) {
      const messages = []

      if (data.errors) {
        Object.getOwnPropertyNames(data.errors).forEach(e => {
          this.valid[e] = false
          messages.push(data.errors[e].join('\n'))
        })
      }

      messages.forEach(el => {
        this.$notify({
          group: 'errors',
          title: data.message,
          text: el,
          type: 'error'
        })
      })
    }

  }
}
</script>
