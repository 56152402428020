<template>
  <div class="wrapper fadeInDown">
    <b-card v-if="!delegator">
      <b-row>
        <b-col cols="12" md="6" lg="4">
          <company-type-ahead-selector
            :selectedCompanyId="filters.company_id"
            @select-company="selectCompany"
          ></company-type-ahead-selector>
        </b-col>

        <b-col cols="12" md="6" lg="4" class="mt-3 mt-md-0">
          <b-form-group label="Assignee">
            <b-form-select v-model="filters.tech_id" :options="techs">
              <template #first>
                <b-select-option :value="null">Anyone</b-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Search">
            <b-form-input v-model="filters.text"></b-form-input>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Ticket ID">
            <b-form-input v-model="filters.ticket_id"></b-form-input>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Status">
            <b-form-select v-model="filters.status">
              <template #first>
                <b-select-option value="open">Open</b-select-option>
                <b-select-option value="closed">Closed</b-select-option>
                <b-select-option value="either">Either</b-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button block @click="searchBtn()">Search</b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card class="mt-4">
      <b-pagination
        v-if="filters.totalItems > filters.max"
        :total-rows="filters.totalItems"
        :per-page="filters.max"
        v-model="filters.page"
        @change="changePage"
        align="right"
      ></b-pagination>
      <div style="max-width: 100%; overflow: auto;">
        <b-table
          :items="tickets"
          :fields="fields"
          hover
          :sort-by.sync="filters.sortBy"
          :sort-desc.sync="filters.desc"
          no-local-sorting
        >
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.key === 'id' ? '5%' : 'auto' }"
            />
          </template>
          <template #cell(description)="data">
             {{ data.item.description | maxLength }}
          </template>
          <template #cell(company)="data">
            {{ data.item.company.name }}
          </template>
          <template #cell(tech)="data">
            {{ data.item.tech ? data.item.tech.firstname + ' ' + data.item.tech.lastname : '' }}
          </template>
          <template #cell(dueDate)="data">
            Submitted: {{ data.item.dates.submitted | date }}
            <span v-if="data.item.dates.due != null"><br />
              Due: {{ data.item.dates.due | date }}
            </span>
          </template>
          <template #cell(promisedDate)="data">
            <span v-if="data.item.dates.promised != null"
              ><br />Promised: {{ data.item.dates.promised | date }}</span
            >
          </template>
          <template #cell(completeDate)="data">
            <span v-if="data.item.dates.completed != null"
              ><br />Completed: {{ data.item.dates.completed | date }}</span
            >
          </template>
          <template #cell(actions)="data">
            <b-button
              @click="closeTicketModal(data.item)"
              size="sm"
              variant="success"
              class="ml-3 float-right"
              v-b-tooltip.hover.top
              title="Set Complete"
              v-if="data.item.completed == null && !delegator"
              ><b-icon icon="check"></b-icon
            ></b-button>
            <b-button
              @click="reopenTicketModal(data.item)"
              size="sm"
              variant="info"
              class="ml-3 float-right"
              v-b-tooltip.hover.top
              title="Re-Open"
              v-if="!data.item.completed == null && !delegator"
              ><b-icon icon="arrow-clockwise"></b-icon
            ></b-button>
            <b-button
              @click="details(data.item)"
              size="sm"
              class="float-right"
              v-b-tooltip.hover.top
              title="Details"
              ><b-icon icon="eye"></b-icon
            ></b-button>
            <b-button
              v-if="!delegator"
              @click="transfer(data.item)"
              size="sm"
              class="ml-2 float-right"
              v-b-tooltip.hover.top
              title="Transfer"
              ><b-icon icon="arrow-bar-right"></b-icon
            ></b-button>
          </template>
        </b-table>
      </div>
      <b-pagination
        v-if="filters.totalItems > filters.max"
        :total-rows="filters.totalItems"
        :per-page="filters.max"
        v-model="filters.page"
        @change="changePage"
        align="right"
      ></b-pagination>
    </b-card>

    <b-modal
      @hidden="resetModal()"
      @ok="closeTicket"
      v-model="showCloseTicket"
      size="lg"
    >
      <div>
        <label>Completed date</label>
        <b-form-datepicker v-model="closeForm.date"></b-form-datepicker>
      </div>
      <b-form-group label="Ticket type" class="mt-4">
        <b-form-select
          :options="types"
          v-model="closeForm.type"
        ></b-form-select>
      </b-form-group>
      <b-form-group label="Completed By" class="mt-4">
        <b-form-select
          :options="techs"
          v-model="closeForm.tech"
        ></b-form-select>
      </b-form-group>

      <b-form-group label="Closing notes" class="mt-4">
        <b-textarea v-model="closeForm.note"></b-textarea>
      </b-form-group>

      <b-checkbox
        v-model="closeForm.email"
        :value="1"
        :unchecked-value="0"
        v-if="closeForm.canEmail"
        >Send Email Notification</b-checkbox
      >
    </b-modal>
    <b-modal
      @hidden="resetModalReopen()"
      @ok="reopenTicket"
      v-model="showReopenTicket"
      size="lg"
    >
      <b-form-group label="Reason/Notes" class="mt-4">
        <b-textarea v-model="reopenForm.note"></b-textarea>
      </b-form-group>
    </b-modal>

    <TicketDetail
      :ticketId="detailId"
      :value="detailId && detailId > 0"
      @closed="detailId = null"
    ></TicketDetail>
    <Transfer
      v-if="techs && techs[0] && techs[0].options"
      :ticketId="ticketId"
      :value="ticketId && ticketId > 0"
      :techs="techs[0].options"
      @closed="closeTransferModal"
    ></Transfer>
  </div>
</template>

<script>
import TicketService from '@/services/TicketService'
import CompanyTypeAheadSelector from '../form/element/CompanyTypeAheadSelector.vue'
import TicketDetail from '@/components/tickets/TicketDetailModal.vue'
import Transfer from '@/components/tickets/TicketTransferModal.vue'
import UserService from '@/services/UserService'
import moment from 'moment'

export default {
  components: {
    CompanyTypeAheadSelector,
    TicketDetail,
    Transfer
  },
  props: {
    delegator: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tickets: [],
      types: [],
      techs: [],
      filters: {
        company_id: null,
        tech_id: null,
        text: null,
        ticket_id: null,
        status: 'open',

        sortBy: 'id',
        desc: true,

        totalItems: 0,
        max: 25,
        page: 1
      },
      fields: [
        { key: 'id', label: 'ID', sortable: true, sortDirection: 'desc' },
        'description',
        { key: 'company', sortable: true },
        { key: 'tech', sortable: true },
        { key: 'dueDate', sortable: true },
        { key: 'promiseDate', sortable: true },
        { key: 'completeDate', sortable: true },
        { key: 'actions', label: '' }
      ],

      closeForm: {
        ticket_id: null,
        date: null,
        tech: null,
        type: null,
        note: null,
        email: 0,
        canEmail: false
      },
      reopenForm: {
        ticket_id: null,
        note: null
      },

      showCloseTicket: false,
      showReopenTicket: false,

      detailId: null,
      ticketId: null,
      showDetail: false,
      showTransfer: false
    }
  },
  methods: {
    search: function () {
      if (!this.delegator) {
        TicketService.search(this.filters).then(result => {
          this.filters.totalItems = result.data.meta.total
          this.filters.page = result.data.meta.current_page
          this.tickets = result.data.data
        })
      } else {
        TicketService.getUndelegated(this.filters).then(result => {
          this.filters.totalItems = result.data.meta.total
          this.filters.page = result.data.meta.current_page
          this.tickets = result.data.data
        })
      }
    },

    details (row) {
      this.detailId = row.id
      this.showDetail = true
      // this.$router.push('/tickets/view/' + row.id)
    },

    transfer (row) {
      this.ticketId = row.id
      this.showTransfer = true
    },

    changePage (page) {
      this.filters.page = page
      this.search()
    },

    searchBtn () {
      this.filters.page = 1
      this.filters.totalItems = 0
      this.search()
    },

    selectCompany: function (comp) {
      if (comp === null) {
        this.clearCompany()
        return
      }
      if (typeof comp === 'object') {
        this.filters.company_id = comp.id
      } else {
        this.filters.company_id = comp
      }
    },

    closeTicketModal (data) {
      this.loadTicketTypes(data.company.id)
      this.closeForm.date = moment().format('YYYY-MM-DD HH:mm:ss')
      const user = UserService.getUserObject()
      if (user && user.token) {
        this.closeForm.tech = user.info.id
      }
      this.closeForm.ticket_id = data.id
      this.closeForm.type = data.tickettype_id
      this.closeForm.email = data.emailnotification
      this.closeForm.canEmail = (data.email && data.email != null && data.email !== '')
      this.showCloseTicket = true
    },

    closeTransferModal () {
      this.ticketId = null
      window.location.reload()
    },

    reopenTicketModal (data) {
      this.reopenForm.ticket_id = data.id
      this.showReopenTicket = true
    },

    closeTicket (bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$updateloading(1)

      TicketService.close(this.closeForm.ticket_id, this.closeForm).then(
        (response) => {
          this.showCloseTicket = false
          this.search()
        },
        (err) => {
          this.$notify({
            group: 'errors',
            title: err.response.request.status,
            text: err.response.data.message,
            type: 'warn'
          })
        }
      ).finally(() => { this.$updateloading(-1) })
    },

    reopenTicket (bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$updateloading(1)

      TicketService.reopen(this.reopenForm.ticket_id, this.reopenForm).then(
        (response) => {
          this.showReopenTicket = false
          this.search()
        },
        (err) => {
          this.$notify({
            group: 'errors',
            title: err.response.request.status,
            text: err.response.data.message,
            type: 'warn'
          })
        }
      ).finally(() => { this.$updateloading(-1) })
    },

    resetModal () {
      this.closeForm = {
        ticket_id: null,
        date: null,
        tech: null,
        type: null,
        note: null,
        email: 0,
        canEmail: false
      }
    },

    resetModalReopen () {
      this.reopenForm = {
        ticket_id: null,
        note: null
      }
    },

    loadTicketTypes: async function (companyid = 0) {
      this.$updateloading(1)
      if (!this.delegator) {
        TicketService.getTicketTypes(companyid)
          .then(res => {
            let currCat = ''
            this.types = []
            const items = []
            res.data.forEach(e => {
              if (e.category !== currCat) {
                items.push({ label: e.category, options: [] })
                currCat = e.category
              }
              items[items.length - 1].options.push({ value: e.id, text: e.description })
            })

            items.forEach(e => {
              this.types.push(e)
            })
          })
          .catch(err => {
            console.log(err)
          }).finally(() => {
            this.$updateloading(-1)
          })
      } else {
        TicketService.getUndelegated(this.filters)
          .then(res => {
            let currCat = ''
            this.types = []
            const items = []
            res.data.forEach(e => {
              if (e.category !== currCat) {
                items.push({ label: e.category, options: [] })
                currCat = e.category
              }
              items[items.length - 1].options.push({ value: e.id, text: e.delegatornotes })
            })

            items.forEach(e => {
              this.types.push(e)
            })
          })
          .catch(err => {
            console.log(err)
          }).finally(() => {
            this.$updateloading(-1)
          })
      }
    },

    getTechs: async function () {
      this.$updateloading(1)
      TicketService.getTechs().then(
        (response) => {
          this.techs = []
          this.techs = response.data
        }
      ).finally(() => { this.$updateloading(-1) })
    }
  },

  watch: {
    'filters.sortBy': function (val) {
      this.searchBtn()
    },
    'filters.desc': function (val) {
      this.searchBtn()
    }
  },

  mounted: function () {
    const user = UserService.getUserObject()
    if (user && user.token) {
      this.filters.tech_id = user.info.id
    }
    if (this.delegator) {
      this.fields = [
        { key: 'id', label: 'ID', sortable: true, sortDirection: 'desc' },
        { key: 'actions', label: '' },
        'description',
        { key: 'company', sortable: true },
        { key: 'tech', sortable: true },
        { key: 'dueDate', sortable: true },
        { key: 'promiseDate', sortable: true },
        { key: 'completeDate', sortable: true }
      ]
    }
    this.search()
    this.loadTicketTypes()
    this.getTechs()
  },

  filters: {
    date (val) {
      const d = moment(val)
      if (d.format('HH:mm a') !== '00:00 am') {
        return d.format('YYYY-MM-DD HH:mm a')
      } else {
        return d.format('YYYY-MM-DD')
      }
    },
    maxLength (val, len = 75) {
      if (val == null) {
        return val
      } else if (val.length > len) {
        return val.substring(0, len) + '...'
      } else {
        return val
      }
    }
  }
}
</script>
