<template>
  <div>
    <TicketSearch :delegator="true" ></TicketSearch>
  </div>
</template>

<script>
import TicketSearch from '@/components/tickets/TicketSearch.vue'

export default {
  components: {
    TicketSearch
  }
}
</script>
