import UserService from '@/services/UserService'
import axios from 'axios'

const TimeOffService = {

  async setCredentials (id, token) {
    return axios({
      url: UserService.baseUrl() + 'api/rocketchat/credentials',
      method: 'POST',
      data: JSON.stringify({ id: id, token: token })
    })
  },

  async getCredentials () {
    return axios({
      url: UserService.baseUrl() + 'api/rocketchat/credentials',
      method: 'GET'
    })
  },

  async getMessages () {
    return axios({
      url: UserService.baseUrl() + 'api/rocketchat/messages',
      method: 'GET'
    })
  },

  async unstar (id) {
    return axios({
      url: UserService.baseUrl() + 'api/rocketchat/unstar',
      method: 'POST',
      data: JSON.stringify({ message_id: id })
    })
  },

  async downloadFile (img) {
    return axios({
      url: UserService.baseUrl() + 'api/rocketchat/getFile',
      method: 'POST',
      data: JSON.stringify({ file_data: img })
    })
  }

}

export default TimeOffService
