<template>
  <div class="row form-group">
    <div class="col-xs-3 col-md-2">
      <label for="server_id">PC/Server:</label>
    </div>
    <div class="col">
      <select
        class="form-control"
        name="server_id"
        v-model="job.device_id"
        :disabled="!job.company_id || !company.devices"
      >
        <option value=""></option>
        <option v-for="s in company.devices" :key="s.id" v-bind:value="s.id">
          {{ s.devicetypedescription }}: {{ s.name }}
          <span v-if="s.user">({{ s.user }})</span>
        </option>
      </select>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    job: {
      type: Object
    },
    company: {
      type: Object
    }
  }
}
</script>
