import UserService from '@/services/UserService'
import axios from 'axios'

const TicketService = {

  async get (id) {
    return axios({
      url: UserService.baseUrl() + 'api/tickets/' + id,
      method: 'GET'
    })
  },

  async addInit () {
    return axios({
      url: UserService.baseUrl() + 'api/tickets/add/init',
      method: 'GET'
    })
  },

  async save (data) {
    return axios({
      url: UserService.baseUrl() + 'api/tickets/',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async update (id, data) {
    return axios({
      url: UserService.baseUrl() + 'api/tickets/' + id,
      headers: { 'content-type': 'application/json' },
      method: 'PATCH',
      data: JSON.stringify(data)
    })
  },

  async editPrivateNote (id, note) {
    return axios({
      url: UserService.baseUrl() + 'api/tickets/privatenote/' + id,
      headers: { 'content-type': 'application/json' },
      method: 'PATCH',
      data: JSON.stringify({ privateinfo: note })
    })
  },

  async attachFiles (id, data) {
    return axios.post(UserService.baseUrl() + 'api/tickets/' + id + '/files', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  async getTicketTypes (companyid = 0) {
    return axios({
      url: 'api/tickets/tickettypes' + (companyid > 0 ? ('/' + companyid) : ''),
      method: 'GET'
    })
  },

  async getTechs () {
    return axios({
      url: UserService.baseUrl() + 'api/tickets/techs',
      method: 'GET'
    })
  },

  async getTickets (id) {
    return axios({
      url: 'api/tickets/mytickets/' + id,
      method: 'POST'
    })
  },

  search (data) {
    return axios({
      url: UserService.baseUrl() + 'api/tickets/search',
      method: 'POST',
      data: { filters: data }
    })
  },

  getUndelegated (data) {
    return axios({
      url: UserService.baseUrl() + 'api/tickets/getUndelegated',
      method: 'POST',
      data: { filters: data }
    })
  },

  transferTicket (data) {
    return axios({
      url: UserService.baseUrl() + 'api/tickets/transferTicket',
      method: 'POST',
      data: { filters: data }
    })
  },

  close (id, data) {
    return axios({
      url: UserService.baseUrl() + 'api/tickets/close/' + id,
      method: 'POST',
      data: data
    })
  },

  reopen (id, data) {
    return axios({
      url: UserService.baseUrl() + 'api/tickets/reopen/' + id,
      method: 'POST',
      data: data
    })
  }
}

export default TicketService
