<template>
  <div v-if="this.contact" style="margin: 0; padding: 0; margin-top: -47px; margin-left: -7px;">
    <table width="800" cellpadding="0" cellspacing="0" border="0">
      <tr>
        <td valign="top" style="font-family:Verdana;font-weight:700;font-size:16px;line-height:1.15;">
          <img src="/img/envelope_logo.png" width="198" height="75" align="left" style="margin-right:10px;"/>
          AIM IT Services<br/>
          4720 E Cotton Gin Loop<br/>
          #135<br/>
          Phoenix, AZ 85040
        </td>
      </tr>
      <tr>
        <td valign="top" style="font-family:Verdana;font-weight:700;font-size:16px;border:none;line-height:1.15;">
          <div style="margin-top:100px;">
            {{ contact.firstname }} {{ contact.lastname }}
            <address v-if="contact.location && contact.location.id" style="white-space: pre;">{{ contact.location.address }}</address>
            <address v-else-if="contact.workaddress" style="white-space: pre;">{{ contact.workaddress }}</address>
            <address v-else style="white-space: pre;">{{ contact.homeaddress }}</address>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import ContactService from '@/services/ContactService'

export default {
  props: {
    id: {
      type: String,
      default: '0'
    }
  },
  data () {
    return {
      contact: null
    }
  },
  created () {
    document.body.style.backgroundColor = 'white'

    ContactService.get(+this.id).then(
      (response) => {
        this.contact = response.data
      }
    )
  }
}
</script>
