<template>
  <div v-if="ticket">
    <div
      class="card border border-dark mb-3"
      v-if="ticket.delegatornotes"
    >
      <div class="card-header py-1">
        <h5 class="card-title text-dark text-center m-0">Delegator Notes</h5>
      </div>
      <div class="card-body pt-3 pb-0 px-3">
        <div>
          <span v-html="ticket.delegatornotes"></span>
        </div>
      </div>
    </div>

    <div
      class="card border border-dark mb-3"
      v-if="ticket.privateinfo"
    >
      <div class="card-header py-1">
        <div class="card-title h5 text-dark text-center m-0">
          <div class="d-inline-block my-2">Private Notes</div>
          <b-button class="float-right" @click="editPrivate = true" v-if="!editPrivate">
            <b-icon icon="pencil-square"></b-icon>
          </b-button>
          <b-button class="float-right" @click="save" v-if="editPrivate" variant="success">
            <b-icon icon="check"></b-icon>
          </b-button>
          <b-button class="float-right mr-2" @click="cancel" v-if="editPrivate" variant="danger">
            <b-icon icon="x"></b-icon>
          </b-button>
        </div>
      </div>
      <div class="card-body pt-3 pb-0 px-3">
        <div>
          <span v-html="ticket.privateinfo" v-if="!editPrivate"></span>
          <b-form-group v-else>
            <b-textarea v-model="textAreaTxt"></b-textarea>
          </b-form-group>
        </div>
      </div>
    </div>

    <div
      v-if="ticket.description"
      class="card border border-dark mb-3"
    >
      <div class="card-header py-1">
        <h5 class="card-title text-dark text-center m-0">Requester Description</h5>
      </div>
      <div class="card-body pt-3 pb-0 px-3">
        <div class="text-left">
          <span v-html="ticket.description"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TicketService from '@/services/TicketService'
export default {
  name: 'TicketDescription',
  props: {
    ticket: {
      type: Object,
      default () { return null }
    }
  },
  data () {
    return {
      editPrivate: false,
      textAreaTxt: ''
    }
  },
  mounted () {
    this.setTextArea()
  },
  methods: {
    setTextArea () {
      if (this.ticket && this.ticket.privateinfo) {
        this.textAreaTxt = this.ticket.privateinfo
      }
    },
    cancel () {
      this.editPrivate = false
      this.setTextArea()
    },
    save () {
      TicketService.editPrivateNote(this.ticket.id, this.textAreaTxt).then(
        (response) => {
          this.editPrivate = false
          this.$emit('refresh')
          this.$notify({
            group: 'notices',
            title: response.data.message,
            type: 'info'
          })
        }
      )
    }
  },
  watch: {
    ticket: {
      handler: function (n, o) {
        if (n) {
          this.setTextArea()
        }
      },
      deep: true
    }
  }
}
</script>
