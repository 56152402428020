<template>
  <div v-if="ticket" class="card border border-dark mb-3">
    <div class="card-header py-1">
      <h5 class="card-title text-dark text-center m-0">Ticket Info</h5>
    </div>
    <div class="card-body p-1">
      <div class="row">
        <div class="col-4 text-right pr-0">Ticket #:</div>
        <div class="col-8 px-2">{{ ticket.id }}</div>
        <div class="col-4 text-right pr-0">Assigned To:</div>
        <div class="col-8 px-2">
          <span v-if="ticket.tech">{{
            ticket.tech.lastname + ", " + ticket.tech.firstname
          }}</span>
        </div>
        <div class="col-4 text-right pr-0">Delegator:</div>
        <div class="col-8 px-2">
          <span v-if="ticket.delegator">
            {{ ticket.delegator.lastname + ", " + ticket.delegator.firstname }}
          </span>
        </div>
        <div class="col-4 text-right pr-0">Court:</div>
        <div class="col-8 px-2">{{ ticket.court }}</div>
        <div class="col-4 text-right pr-0">Type:</div>
        <div class="col-8 px-2">
          <span v-if="ticket.tickettype">{{ ticketTypeCategory }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TicketInfo',
  props: {
    ticket: {
      type: Object,
      default () { return null }
    }
  },
  computed: {
    ticketTypeCategory () {
      var category = ''
      if (this.ticket && this.ticket.tickettype) {
        if (this.ticket.tickettype.category) {
          category += this.ticket.tickettype.category + ' / '
        } else if (this.ticket.tickettype.categoryinfo) {
          category += this.ticket.tickettype.categoryinfo.description + ' / '
        }
        if (this.ticket.tickettype.shortdesc) {
          category += this.ticket.tickettype.shortdesc
        } else if (this.ticket.tickettype.description) {
          category += this.ticket.tickettype.description
        }
      }
      return category
    }
  }
}
</script>
