import UserService from '@/services/UserService'
import axios from 'axios'

const TimeOffService = {

  async init () {
    return axios({
      url: UserService.baseUrl() + 'api/timeoff/init',
      method: 'GET'
    })
  },

  async save (data) {
    return axios({
      url: UserService.baseUrl() + 'api/timeoff/',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async listOthers () {
    return axios({
      url: UserService.baseUrl() + 'api/timeoff/listOthers/',
      method: 'GET'
    })
  }
}

export default TimeOffService
