<template>
  <div>
    <label class="form-label" for="company-select" v-show="!hideLabel">
      <slot name="label">Company:</slot>
    </label>
    <vue-typeahead-bootstrap
      v-show="companyId == null"
      :data="companies"
      v-model="companySearch"
      :serializer="(s) => s.name"
      @hit="selectCompany($event)"
      :inputClass="!isValid ? 'form-control is-invalid' : 'form-control'"
      inputName="company-select"
    ></vue-typeahead-bootstrap>
    <div class="input-group" v-if="companyInfo">
      <span class="form-control alert-info">
        {{ companyInfo.name }}
      </span>
      <div class="input-group-append">
        <button
          type="button"
          class="btn btn-outline-secondary"
          @click="clearCompany()"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <input type="hidden" v-model="companyId" :required="required" />
  </div>
</template>
<script>
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import UserService from '@/services/UserService'
import axios from 'axios'
export default {
  components: {
    VueTypeaheadBootstrap
  },
  // this makes vetur pick up the @ auto-complete for this, but breaks the template interoplation here which annoys me more.
  // emits: ['select-company'],
  props: {
    selectedCompanyId: {
      type: [Number, String],
      default () {
        return null
      }
    },
    hideLabel: {
      type: Boolean,
      default () {
        return false
      }
    },
    isValid: {
      type: Boolean,
      default () {
        return true
      }
    },
    required: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      companies: [],
      companyId: this.selectedCompanyId,
      companySearch: ''
    }
  },
  computed: {
    /**
     * @returns {Object}
     */
    companyInfo: function () {
      return this.companies.find(x => (+x.id) === (+this.companyId))
    }
  },
  methods: {
    /** Load all ATMS companies used for type ahead
     * @returns {Promise}
     */
    getCompanies: function () {
      this.$updateloading(1)
      return axios({
        url: UserService.baseUrl() + 'api/companies',
        method: 'GET'
      }).then(response => {
        this.companies = response.data
      }).finally(() => {
        this.$updateloading(-1)
      })
    },
    /**
     * @returns {Void}
     */
    selectCompany (company) {
      this.companyId = company.id
      this.companySearch = ''
      this.$emit('select-company', company)
    },
    clearCompany () {
      this.companyId = null
      this.$emit('select-company', null)
    }
  },
  mounted () {
    this.getCompanies()
  },
  watch: {
    /**
     * If the bound selectedCompanyId is changed
     * from the parent component, update our
     * internal selection
     */
    selectedCompanyId (n, o) {
      this.companyId = n
    }
  }
}
</script>
