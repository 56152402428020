<template>
  <div v-if="invalid" class="alert alert-danger">
    Your credentials are invalid or have expired.
    <br />
    Please <router-link to="/login">login</router-link> to continue.
  </div>
</template>
<script>
import UserService from '@/services/UserService'

export default {
  name: 'LoginHash',
  data () {
    return {
      loading: false,
      invalid: false
    }
  },

  mounted () {
    this.checkHash()
  },

  methods: {
    checkHash () {
      const params = new URLSearchParams(location.search)
      const hash = params.get('hash')
      const userId = params.get('userId')
      if (!hash || !hash.length || !userId) {
        this.invalid = true
        return false
      }
      UserService.loginHash(userId, hash).then(
        () => {
          this.$router.push('/')
        }
      ).catch(() => {
        this.invalid = true
      })
    }
  }
}
</script>
