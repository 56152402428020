<template>
  <div>
    <b-row>
      <b-col cols="12" md="2" class="py-2">
        <label class="d-block d-md-none mb-0">Category</label>
        <b-form-select
          v-model="hardware.category"
          :options="categoriesFormatted"
          @input="fillCategory(hardware.category)"
        >
          <template #first>
            <b-form-select-option :value="null" disabled></b-form-select-option>
            <b-form-select-option :value="'other'"
              >Input other hardware</b-form-select-option
            >
          </template>
        </b-form-select>
      </b-col>
      <b-col cols="12" md="6" class="py-2">
        <label class="d-block d-md-none mb-0">Description</label>
        <b-form-select
          v-model="selectedDesc"
          :options="categoryOptions[hardware.category]"
          v-if="hardware.category != 'other'"
        >
        </b-form-select>
        <b-form-input v-model="hardware.description" v-else> </b-form-input>
      </b-col>
      <b-col cols="12" md="1" class="py-2">
        <label class="d-block d-md-none mb-0">Qty</label>
        <b-form-input v-model="hardware.quantity"></b-form-input>
      </b-col>
      <b-col cols="12" md="1" class="py-2">
        <label class="d-block d-md-none mb-0">Price</label>
        <b-form-input v-model="hardware.price"></b-form-input>
      </b-col>
      <b-col cols="12" md="1" sm="6" class="py-2">
        <label class="d-block d-md-none mb-0">Non Taxable</label>
        <b-form-checkbox
          value="1"
          unchecked-value="0"
          v-model="hardware.nontaxable"
        ></b-form-checkbox>
      </b-col>
      <b-col cols="12" md="1" sm="6" class="py-2">
        <b-button @click="$emit('removeHardware')">
          <i class="fa fa-remove"></i>
          <span class="d-md-none"> Remove Hardware</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  props: {
    hardware: {
      type: Object
    },
    categories: {
      type: Array
    }
  },
  computed: {
    /**
    * @returns {Object}
    */
    categoriesFormatted: function () {
      return this.categories.map(
        ({ category, description }) => {
          return {
            value: category,
            text: description
          }
        }
      )
    }
  },
  watch: {
    selectedDesc: function (n, o) {
      if (n !== o && n !== null) {
        this.hardware.description = n.description
        this.hardware.sku = this.hardware.category === 'other' ? null : n.sku
      }
    }
  },
  data: () => {
    return {
      categoryOptions: {},
      selectedDesc: null
    }
  },
  created: function () {
    this.categories.forEach(e => {
      Object.assign(this.categoryOptions, { [e.category]: [] })
    })
    // pre-fill the selectedDesc item tied to the drop down
    this.fillCategory(this.hardware.category)
    this.selectedDesc = {
      description: this.hardware.description,
      sku: this.hardware.category === 'other' ? null : this.hardware.sku
    }
  },
  methods: {
    fillCategory: function (category) {
      if (category && category !== '' && category !== 'other') {
        if (this.categoryOptions[category].length === 0) {
          this.$updateloading(1)
          axios.post('api/hardware/categoryoptions', { category: category }).then(response => {
            this.categoryOptions[category] = response.data.map(
              ({ hid, sku, description }) => {
                return {
                  text: description,
                  value: {
                    sku: sku,
                    description: description
                  }
                }
              }
            )
          }).finally(() => {
            this.$updateloading(-1)
            // this force update is the only way i found that can force the front end to rerender so the description option is filled correctly
            this.$forceUpdate()
          })
        }
      }
    }
  }
}
</script>
