<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <label for="promised-date">{{ label }} Date</label>
      <div class="input-group">
        <input
          id="promised-date"
          type="text"
          class="form-control"
          :value="date"
          :class="
            state === false && (date == '' || date == null) ? 'is-invalid' : ''
          "
          @input="$emit('setDate', $event.target.value)"
        />
        <div class="input-group-append">
          <b-form-datepicker
            right
            type="text"
            :value="date"
            @input="$emit('setDate', $event)"
            button-only
          />
          <b-dropdown right variant="secondary">
            <b-dropdown-item
              v-for="(v, k) in options"
              :key="k"
              @click="$emit('setDate', v.value)"
            >
              {{ v.text }}
            </b-dropdown-item>
          </b-dropdown>
          <b-button @click="$emit('setDate', null)">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <label for="promised-time">{{ label }} Time</label>
      <div class="input-group">
        <b-form-timepicker
          right
          class="form-control"
          type="text"
          :value="time"
          :class="
            state === false && (time == '' || time == null) ? 'is-invalid' : ''
          "
          @input="$emit('setTime', $event)"
        />
        <div class="input-group-append">
          <b-dropdown right>
            <b-dropdown-item
              v-for="(v, k) in timeoptions"
              :key="k"
              @click="$emit('setTime', v.value)"
            >
              {{ v.text }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    date: {
      type: String,
      default () { return null }
    },
    time: {
      type: String,
      default () { return null }
    },
    options: {
      type: Array,
      default () { return [] }
    },
    timeoptions: {
      type: Array,
      default () { return [] }
    },
    label: {
      type: String,
      default () { return '' }
    },
    state: {
      type: Boolean,
      default () { return null }
    }
  },

  watch: {
    date: function (n, o) {
      this.$emit('resetState')
    },
    time: function (n, o) {
      this.$emit('resetState')
    }
  }
}
</script>

<style lang="css" scoped>
.input-group >>> .dropdown-menu {
  max-height: 450px;
  overflow-y: auto;
}
</style>
