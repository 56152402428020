<template>
  <div>
    <div class="row form-group">
      <div class="col-xs-3 col-md-2">
        <label for="redmine_id">Redmine:</label>
      </div>
      <div class="col">
        <select
          class="form-control"
          name="redmine_id"
          v-model="job.redmine_id"
          :disabled="!job.company_id || !company.redmineissues"
          @change="updateFromRedmine()"
        >
          <option value=""></option>
          <option
            v-for="r in company.redmineissues"
            :key="r.id"
            v-bind:value="r.id"
          >
            {{ r.id }} - {{ r.subject }}
          </option>
        </select>
      </div>
    </div>
    <div class="row form-group" v-show="job.redmine_id">
      <div class="col-xs-3 col-md-2">
        <label for="redmine_id">Activity:</label>
      </div>
      <div class="col">
        <select
          class="form-control"
          name="redmine_id"
          v-model="job.redmineactivityid"
          :disabled="!job.redmine_id"
        >
          <option value="" disabled>Select Redmine Activity</option>
          <option
            v-for="r in redmineactivities"
            :key="r.id"
            v-bind:value="r.id"
          >
            {{ r.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    job: {
      type: Object
    },
    company: {
      type: Object
    },
    redmineactivities: {
      type: Array
    }
  },
  methods: {
    updateFromRedmine () {
      this.$emit('update-from-redmine')
    }
  }
}
</script>
