<template>
  <div class="row form-group">
    <div class="col-xs-3 col-md-2">
      <label for="contact_id">Requester:</label>
    </div>
    <div class="col">
      <select
        class="form-control"
        name="contact_id"
        v-model="job.contact_id"
        :disabled="!job.company_id || !company.contacts"
      >
        <option value=""></option>
        <option v-for="t in company.contacts" :key="t.id" v-bind:value="t.id">
          {{ t.firstname }} {{ t.lastname }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    job: {
      type: Object
    },
    company: {
      type: Object
    }
  }
}
</script>
