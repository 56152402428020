<style>
.col-start-stop-icon {
  flex: 0 0 5%;
}

.job-list {
  padding: 0;
}

.job-list-row {
  margin-left: 0 !important;
}

.job-heading {
  font-weight: bold;
}

.job-description {
  font-size: 80%;
}

.job-list-row p {
  margin: 0 !important;
}

.job-description>p {
  margin-bottom: 0 !important;
}

.time-stats tr {
  background: #ddffff;
}
</style>

<style lang="sass" scoped>
@import "node_modules/bootstrap/scss/bootstrap"
@debug $primary
@debug 'test'

.jobtype1
  background-color: rgba(gold, 0.3)
.jobtype2
  background-color: rgba($danger, 0.2)
.jobtype5
  background-color: rgba($primary, 0.3)
.jobtype8
  background-color: rgba($success, 0.3)
.jobtype9
  background-color: rgba($warning, 0.3)
.jobtype10
  background-color: rgba(#E7E7E7, 0.3)
.jobtype11
  background-color: rgba(goldenrod, 0.3)
.jobtype12
  background-color: rgba(violet, 0.3)
.jobtype22
  background-color: rgba(#D3ACD4, 0.3)
.jobtype32
  background-color: rgba($danger, 0.5)
.jobtype33
  background-color: rgba($primary, 0.5)
</style>

<template>
  <div class="fadeInDown">
    <div class="card">
      <div class="card-header w-100 p-1 py-2">
        <div class="d-flex h-100 p-0">
          <div class="row align-self-center w-100 p-0 m-0">
            <div class="col">
              <h1 class="p-0 m-0">Jobs</h1>
            </div>
            <div class="col text-right">
              <div class="btn-group">
                <router-link :to="{ path: '/jobs/add' }" tag="button" class="btn btn-info btn-sm">Add Job</router-link>
                <b-dropdown :right="true" v-if="!hasMiscJob || !hasLunchJob">
                  <b-dropdown-item @click="addMiscJob()" v-show="!hasMiscJob">
                    Aim/Misc
                  </b-dropdown-item>
                  <b-dropdown-item @click="addLunchJob()" v-show="!hasLunchJob">
                    Lunch/Break
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="ticketList.length > 0" class="top-alert">
        <h4 class="no-margin"><u>Tickets Needing Review</u></h4>
        <ul class="list-unstyled no-margin-bottom">
          <li v-for="t in ticketList" :key="t.id">
              <a href="#" @click="details(t)">{{ t.id }} </a>
              <b-button-group size="sm">
                <b-button @click="acknowledgeTicket(t)" variant="light"><b-icon icon="check-circle" title="Acknowledge Ticket" font-scale="1.25"></b-icon></b-button>
                <b-button @click="details(t)" variant="light"><b-icon icon="eye-fill" title="Ticket Details" font-scale="1.25"></b-icon></b-button>
                <b-button @click="quickStartNewJob(t)" variant="light"><b-icon icon="wrench" title="Quick Add a Job" font-scale="1.25"></b-icon></b-button>
              </b-button-group>
            <div class="unack-promised">
              {{ t.promised }}
            </div>
            {{ t.client ? t.client + ': ' : '' }}
              {{ t.ttype }} - {{ t.description }}
          </li>
        </ul>
      </div>
      <!-- start job list -->
      <div class="card-body job-list p-0">
        <div class="list-group-flush">
          <div class="fadeIn second list-group-item p-0" v-for="job in sortJobList"
            :class="['jobminimized' ? job.id != expanded : '']" :key="job.id">
            <div class="d-flex h-100 p-0 my-1">
              <div class="row job-list-row w-100 align-items-center p-1" :class="['jobtype' + job.jobtype_id]">
                <div class="col-start-stop-icon">
                  <button class="btn btn-sm btn-default p-0" :class="job.starttime ? 'text-danger' : 'text-success'"
                    @click="toggleJobActive(job)" v-if="job.jobtype_id != 10">
                    <span v-if="job.starttime" class="fa fa-pause" alt="Stop Job"></span>
                    <span v-else class="fa fa-play"></span>
                  </button>
                </div>
                <div class="col-9">
                  <div class="job-heading" :style="'background: ' + job.background">
                    {{ job.company.name }}
                    <button @click="toggleExpanded(job.id)" class="btn btn-sm btn-default p-0">
                      <span v-show="expanded != job.id" class="fa fa-angle-double-right"></span>
                      <span v-show="expanded == job.id" class="fa fa-angle-double-left"></span>
                    </button>
                    <div class="float-right" v-show="job.jobtype_id == 11 && job.location_id > 0">
                      <a class="btn btn-sm btn-default p-0" :href="getMapLink(job)" target="_blank">
                        <span class="fa fa-map-marker"></span>
                      </a>
                    </div>
                  </div>
                  <div class="job-heading">
                    <a :href="
                      'https://redmine.aimitservices.com/issues/' +
                      job.redmine
                    " target="_blank" v-if="job.redmine > 0">RM-{{ job.redmine }} &nbsp;
                    </a>
                    <span v-if="job.ticket_id > 0">
                      <a href="#" v-on:click.prevent="showTicketDetail(job.ticket_id)">
                        ATMS {{ job.ticket_id }}
                      </a>
                    </span>
                  </div>
                  <div v-if="job.id == expanded && job.contact_id > 0">
                    {{ job.contact.firstname }}
                    {{ job.contact.firstname }}

                    <a :href="'mailto:' + job.contact.email" v-show="job.contact.email">
                      ({{ job.contact.email }})
                    </a>

                    <div v-show="job.contact.phone">
                      <a :href="'tel:' + job.contact.phone">
                        {{ job.contact.phone }}
                      </a>
                    </div>
                  </div>
                  <div class="job-description" v-show="job.id != expanded">
                    {{ job.description | truncate(55) }}
                  </div>
                  <div class="job-description" v-show="job.id == expanded" v-html="job.markdowndescription"></div>
                </div>
                <div class="col text-right">
                  <!-- job time -->
                  <div v-if="job.jobtype_id != 10">
                    <strong>{{ getJobTime(job) }}</strong>
                  </div>
                  <div v-if="job.jobtype_id != 10">
                    <router-link :to="{ path: '/jobs/edit/' + job.id }" tag="div">
                      <span class="fa fa-edit"></span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer w-100 p-1 py-2">
        <div class="d-flex h-100 p-0">
          <div class="row align-self-center w-100 p-0 m-0">
            <div class="col-9" v-show="!calendarVisible">
              <a href="#" v-show="!calendarVisible" @click.prevent="calendarVisible = !calendarVisible">{{
                businessDateFormatted }}</a>
            </div>
            <div class="col text-right" v-show="!calendarVisible">
              <strong>{{ getTotalTime() }}</strong>
            </div>
            <div class="col" v-show="calendarVisible">
              <b-calendar v-model="businessDate" :block="true" @selected="calendarVisible = false"></b-calendar>
            </div>
          </div>
        </div>
      </div>
    </div>

    <JobTotals :job-list="jobList"></JobTotals>
    <TicketDetailModal v-model="showDetailModal" :ticketId="detailProps.id"></TicketDetailModal>
    <TicketDetailModal
      :ticketId="detailId"
      :value="detailId && detailId > 0"
      @closed="detailId = null"
    ></TicketDetailModal>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import UserService from '@/services/UserService'
import JobTotals from '@/components/jobs/JobTotals'
import TicketDetailModal from '@/components/tickets/TicketDetailModal'

export default {
  name: 'Jobs',
  components: { JobTotals, TicketDetailModal },
  data: function () {
    return {
      user: null,
      businessDate: new Date(),
      jobList: [],
      ticketList: [],
      jobTypes: [],
      stats: [],
      techs: [],
      expanded: null,
      calendarVisible: false,
      timer: null,
      refresh: 0,
      showDetailModal: false,
      detailComponent: null,
      detailId: null,
      detailProps: {}
    }
  },
  watch: {
    businessDate: function (n, o) {
      if (n !== o) {
        this.loadJobs(false)
        this.calendarVisible = !this.calendarVisible
      }
    }
  },
  computed: {
    /**
     * @returns {String}
     */
    businessDateFormatted: function () {
      return moment(this.businessDate).format('MMM Do YYYY')
    },
    /**
     * @returns {Object}
     */
    runningJob: function () {
      var job = null
      this.jobList.forEach(j => {
        if (j.starttime !== null) {
          job = j
        }
      })
      return job
    },
    /**
     * @returns {Boolean}
     */
    hasMiscJob: function () {
      var has = false
      var misctext = UserService.getUserObject().miscjobtext
      if (!misctext) {
        misctext = 'ticket/email administration'
      }
      this.jobList.some(x => {
        if (x.description.substring(0, misctext.length) === misctext) {
          has = true
          return true
        }
      })
      return has
    },
    /**
     * @returns {Boolean}
     */
    hasLunchJob: function () {
      var has = false
      this.jobList.some(x => {
        if (parseInt(x.jobtype_id) === 9) {
          has = true
          return true
        }
      })
      return has
    },
    /**
     * @returns {String}
     */
    jobClassObject: function (job) {
      console.log(job)
      return 'jobtype-' + job.jobtype_id
    },
    /**
     * @returns {Array}
     */
    sortJobList: function () {
      if (this.refresh > -1) {
        return _.orderBy(this.jobList, ['starttime', 'ts'], ['asc', 'desc'])
      } else {
        return _.orderBy(this.jobList, ['starttime', 'ts'], ['asc', 'desc'])
      }
    }
  },
  methods: {
    init: async function () {

    },

    details (row) {
      this.detailId = row.id
      this.showDetail = true
      // this.$router.push('/tickets/view/' + row.id)
    },

    loadTickets: async function () {
      axios.post('api/tickets/mytickets/' + UserService.getUserId()).then(response => {
        if (response) {
          this.ticketList = response.data
        }
        this.$updateloading(-1)
      }).catch(error => {
        console.dir(error)
      })
    },

    quickStartNewJob (ticket) {
      this.$router.push('/jobs/add?ticket_id=' + ticket.id)
    },

    acknowledgeTicket (ticket) {
      axios.post('api/tickets/acknowledgeticket/' + ticket.id).then(response => {
        if (response) {
          this.ticketList.splice(ticket, 1)
        }
        this.$updateloading(-1)
      }).catch(error => {
        console.dir(error)
      })
    },

    loadJobs: async function (silentUpdate) {
      // when an auto refresh is done, no need to show loading overlay
      if (silentUpdate !== true) {
        this.$updateloading(1)
      }
      axios.get('api/jobs/my?date=' + this.businessDateFormatted).then(response => {
        if (response) {
          this.jobList = response.data.data
        }
        this.$updateloading(-1)
      }).catch(error => {
        console.dir(error)
      })
    },
    getJobTime: function (job) {
      var t = parseFloat(job.totaltime) || 0
      if (job.starttime) {
        var diff = moment()
        var jStart = moment(job.starttime)
        var diffTime = ((diff.valueOf() - jStart.valueOf()) / (1000 * 60 * 60))
        var cJTime = diffTime
        t += cJTime
      }
      return Math.round(t * 100) / 100
    },
    getTotalTime: function () {
      var t = 0
      if (this.jobList) {
        this.jobList.forEach((job) => {
          t += (this.getJobTime(job) * 100)
        })
      }
      return Math.round(t) / 100
    },
    toggleJobActive: async function (job) {
      this.$updateloading(1)
      var action = 'start'
      if (job.starttime) {
        action = 'stop'
      }
      try {
        const response = await axios.post(UserService.baseUrl() + `api/jobs/${job.id}/${action}`)
        if (response) {
          this.loadJobs(false)
        }
        this.$updateloading(-1)
      } catch (error) {
        console.dir(error)
      }
    },
    toggleExpanded: function (jobId) {
      if (this.expanded === jobId) {
        this.expanded = null
      } else {
        this.expanded = jobId
      }
    },
    getMapLink: function (job) {
      if (job.location_id > 0) {
        return 'https://maps.google.com/?q=' + JSON.stringify('' + (job.location.address + ',' + job.location.city + ',' + job.location.state))
      }
    },
    /**
     * Create the daily general/misc job short-cut
     */
    addMiscJob: function () {
      var misctext = UserService.getUserObject().miscjobtext
      if (!misctext) {
        misctext = 'ticket/email administration'
      }
      axios({
        url: UserService.baseUrl() + 'api/jobs/',
        method: 'POST',
        data: {
          company_id: 30,
          description: misctext,
          startnow: true,
          jobtype_id: 2,
          jdate: new Date(),
          user_id: UserService.getUserId()
        }
      }).then(success => {
        // "stop" the running job locally in view so we don't have to refresh
        var j = this.runningJob
        if (j) {
          j.starttime = null
        }
        this.jobList.push(success.data)
      }, error => {
        var errs = []
        for (var i in error.response.data.errors) {
          error.response.data.errors[i].forEach(m => {
            errs.push(m)
          })
        }
        this.$notify({
          group: 'errors',
          title: error.response.data.message,
          text: errs.join('<br>'),
          type: 'warn'
        })
      })
    },
    /**
     * Create the daily lunch/personal break job
     */
    addLunchJob: function () {
      var misctext = UserService.getUserObject().miscjobtext
      if (!misctext) {
        misctext = 'Lunch/Personal Break'
      }
      axios({
        url: UserService.baseUrl() + 'api/jobs/',
        method: 'POST',
        data: {
          company_id: 30,
          description: misctext,
          startnow: true,
          jobtype_id: 9,
          jdate: new Date(),
          user_id: UserService.getUserId()
        }
      }).then(success => {
        // "stop" the running job locally in view so we don't have to refresh
        var j = this.runningJob
        if (j) {
          j.starttime = null
        }
        this.jobList.push(success.data)
      }, error => {
        var errs = []
        for (var i in error.response.data.errors) {
          error.response.data.errors[i].forEach(m => {
            errs.push(m)
          })
        }
        this.$notify({
          group: 'errors',
          title: error.response.data.message,
          text: errs.join('<br>'),
          type: 'warn'
        })
      })
    },
    createInterval: function () {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.timer = setInterval(() => {
        this.loadJobs(true)
        this.loadTickets()
      }, 36000)
    },
    showTicketDetail: function (ticketId) {
      this.showDetailModal = true
      this.detailProps = { id: ticketId }
      // this.detailComponent = TicketDetail
    }
  },
  created: function () {
    // reset any loading stuff in case we were redirected from another module and auth expired
    this.$updateloading(-999)
    this.user = UserService.getUser()
    this.loadJobs(false)
    this.loadTickets()
    this.init()
    this.createInterval()
  },
  destroyed: function () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
</script>
