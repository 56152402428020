<template>
  <div class="card" v-if="company.tickets">
    <div class="card-header w-100">
      <div class="">
        <div class="row align-self-center w-100">
          <div class="col">
            <h5 @click="expanded = !expanded">Tickets</h5>
          </div>
          <div class="col text-right">
            &nbsp;
            <a href="#" v-on:click.prevent="expanded = !expanded">
              <span class="fas fa-angle-double-down" v-show="!expanded"></span>
              <span class="fas fa-angle-double-up" v-show="expanded"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body p-0 px-2 mt-3" v-show="expanded">
      <div v-show="company.ticketMeta.last_page > 1">
        <b-pagination
          :total-rows="company.ticketMeta.total"
          :per-page="company.ticketMeta.per_page"
          :value="company.ticketMeta.current_page"
          align="right"
          @change="$emit('ticket-page', $event)"
        ></b-pagination>
      </div>
      <div class="list-group-flush">
        <div class="list-group-item p-0">
          <div class="container d-flex h-100 p-1">
            <div class="row w-100 align-items-center">
              <div class="col-1">
                <input type="radio" v-model="ticketId" value="" />
              </div>
              <div class="col">Do Not Link To Ticket</div>
            </div>
          </div>
          <div
            class="container d-flex h-100 p-1"
            v-for="t in company.tickets"
            :key="t.id"
          >
            <div class="row w-100 align-items-center">
              <div class="col-1">
                <input type="radio" v-model="ticketId" v-bind:value="t.id" />
              </div>
              <div class="col">
                <strong>{{ t.id }}</strong>
                <span v-if="t.tech"
                  >: {{ t.tech.firstname }} {{ t.tech.lastname }}</span
                >
                <br />
                <p class="p-0 small" v-html="t.description">
                  <span v-if="t.requester">{{ t.requester.name }}: </span>
                  {{ t.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    job: {
      type: Object
    },
    company: {
      type: Object
    }
  },
  data () {
    return {
      expanded: false,
      ticketId: this.job.ticket_id
    }
  },
  watch: {
    ticketId: function (n, o) {
      this.$emit('select-ticket', n)
    },
    'job.ticket_id': function (n, o) {
      this.ticketId = n
    }
  }
}
</script>
